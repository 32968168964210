//--アーカイブ・詳細ページ投稿用レイアウト--
@use "variables"as *;

article {
	position: relative;

	@include mq(sm) {
		padding: 70px 0;
	}

	.archive & {
		background-color: $gray-lighter;
	}
}

.pg {
	&_wrapper {
		max-width: 1000px;
		margin: 0 auto;

		@include mq(sm) {
			padding: 0 5%;
		}
	}

	&_title {
		font-size: 30px;
		margin: 0 0 50px;
		color: $main-color;
		text-align: center;

		@include mq(sm) {
			font-size: 24px;
		}
	}

	&_contents {
		background-color: $white;
		padding: 50px;
		max-width: 1000px;
		margin: 0 auto;

		@include mq(sm) {
			padding: 50px 20px;
		}
	}
}

.posts {
	&_item_list {
		display: flex;
		flex-wrap: wrap;

		.posts_item {
			margin-right: 20px;
			width: 100%;

			&:nth-of-type(3n) {
				margin-right: 0;
			}

			@include mq(sm) {
				margin-bottom: 40px;
			}

			a {
				display: block;
			}
		}
	}

	&_item {
		margin-bottom: 16px;
		padding-bottom: 16px;
		border-bottom: 1px solid $gray-light;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&_tmb {
		margin: 0;

		img {
			box-shadow: 1px 1px 3px #bfbfbf;
			border-radius: 0.25rem;
			height: 180px;
			max-width: 100%;
			object-fit: cover;
			object-position: center;
			width: 320px;

			@include mq(sm) {
				/* height: calc(((100vw - 40px) / 160) * 90); */
				width: 100%;
				border-radius: 10px;
			}
		}
	}

	&_date {
		color: $main-color;
		display: inline-block;
		margin-bottom: 5px;
		font-size: 13px;
	}

	&_title {
		font-size: 16px;
		line-height: 2;
		margin: 0 0 10px;
	}

	&_cat {
		span {
			border: 1px solid;
			color: $main-color;
			font-size: 12px;
			margin-right: 5px;
			padding: 2px 5px;
		}
	}
}

.no_post {
	max-width: 1000px;
	margin: 0 auto;
	position: relative;

	@include mq(sm) {
		padding: 50px 20px 20px;

		.home & {
			margin-top: 20px;
			padding: 0 0 30px;
		}
	}

	h1 {
		font-size: 30px;
		margin: 0 0 50px;
		color: $main-color;
		text-align: center;

		@include mq(sm) {
			font-size: 24px;
			margin-bottom: 30px;
		}
	}
}

.posts_content {
	margin-top: 30px;

	iframe {
		width: 100%;
		margin-bottom: 30px;
	}
}

// archive blog template
.pg-blog {
	.blog_tmb {
		margin: 0 0 15px 0;
	}
}

// single pg
.pg_single {
	&_title {
		color: $text-color;
		margin-bottom: 10px;
		text-align: left;
	}

	&_contents {
		padding-bottom: 0;
		padding-top: 0;
		max-width: 820px;

		>.blog_tmb {
			margin-bottom: 30px;

			img {
				height: calc(100% / 16 * 9);
				width: 100%;
			}
		}

		@include mq(md) {
			padding-bottom: 0;
			padding-top: 0;
		}

		@include mq(sm) {
			padding-bottom: 0;
			padding-top: 0;
		}

		.breadcrumb_block {
			margin-bottom: 50px;
		}
	}
}

//archive news
.pg-news {
	.posts_item {
		margin-bottom: 30px;
		padding-bottom: 10px;
		border-bottom: 1px solid $gray;
	}

	.posts_title {
		margin: 0;
	}
}

.wp-block-image {
	margin: 0 0 2em 0;

	img {
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}

.wp-block-buttons {
	justify-content: center;
}

.wp-block-button__link {
	display: block;
	width: 300px;
	font-size: 14px !important;
	padding: 10px 0;
	border-radius: 50px;
	text-align: center;
	margin: 20px auto;
	text-decoration: none;

	@include mq(sm) {
		width: 250px;
		font-size: 16px !important
	}
}