@use "variables"as *;

footer {
	width: 83.3333333333%;
	margin-left: auto;
	border-top: 1px solid $gray-light;
	padding-top: 120px;

	@include mq(md2) {
		width: 100% !important;
	}

	@include mq(sm) {
		width: 100% !important;
		padding-top: 80px;
	}

	.footer_inner {
		.row {
			align-items: center;
			justify-content: space-between;
			--bs-gutter-x: 0rem;
			--bs-gutter-y: 0;
			margin-bottom: 110px;

			@include mq(sm) {
				margin-bottom: 80px;
			}

			>* {
				padding: 0;
			}

			.footer_nav,
			.footer_shopinfo_text {
				width: 50%;
				margin-left: auto;
				margin-right: auto;

				@include mq(sm) {
					width: 85%;
				}
			}


			.footer_shopinfo_text {
				.footer_logo {
					img {
						width: 250px;
						height: 100%;
						margin-bottom: 40px;

						@include mq(sm) {
							width: 200px;
						}
					}
				}

				p {
					font-size: 16px;
					color: $gray-deep;

					@include mq(md) {
						font-size: 14px;
					}
				}
			}

			.footer_nav {
				width: 80%;

				.footer-nav-link {
					border-bottom: 1px solid $gray-deep;
					padding-bottom: 5px;
					margin-bottom: 5px;
					color: $gray-deep;
					display: block;
					font-size: 14px;
					font-weight: 500;

					@include mq(sm) {
						margin-top: 40px;
					}

				}

				ul {
					display: flex;
					flex-wrap: wrap;

					&:last-child {
						@include mq(sm) {
							margin-bottom: 0;
						}
					}

					li {
						margin-top: 10px;
						margin-bottom: 10px;

						a {
							color: $gray-deep;
							padding-right: 25px;
							font-size: 14px;
						}
					}
				}

				.product-link {
					margin-bottom: 40px;

					@include mq(sm) {
						margin-bottom: 20px;
					}

					li {


						a {
							position: relative;
							padding-left: 15px;

							&::before {
								content: "";
								display: block;
								width: 0;
								height: 0;
								padding-left: 10px;
								border-left: 6px solid $main-color;
								border-top: 4px solid transparent;
								border-bottom: 4px solid transparent;
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
							}
						}
					}
				}
			}
		}
	}
}




.copy {
	text-align: center;
	// padding-bottom: 20px;
	padding: 15px 0;
	background: $gray;

	small {
		// color: $gray-deep;
		font-size: 12px;
	}

	@include mq(sm) {
		padding: 20px 0 90px;
	}
}


.pagetop_block {
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 9;

	@include mq(sm) {
		display: flex;
		flex-wrap: wrap;
	}

	.contact_sp {
		@include mq(md) {
			display: none !important;
		}

		@include mq(md2) {
			display: none !important;
		}

		@include mq(xl) {
			display: none !important;
		}

		display: flex;
		justify-content: center;
		align-items: center;
		width: 80%;

		a {
			background: $blue;
			padding: 10px 30px;
			height: 60px;
			width: 100%;
			text-decoration: none;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 18px;
			color: $white;

			&::before {
				font-family: "fontello";
				content: "\e801";
				font-size: 16px;
				margin-right: 10px;
				font-weight: normal;
			}

			@include mq(sm) {
				opacity: 1 !important;
			}
		}
	}

	#pagetop {
		width: 60px;
		height: 60px;
		margin-left: auto;

		@include mq(sm) {
			width: 20%;
			height: 60px;
		}

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			background: $main-color;
			width: 100%;
			height: 100%;
			text-decoration: none;
			color: $white;

			&::before {
				font-family: "fontello";
				content: "\e800";
				font-size: 16px;
				line-height: 1;
			}

			@include mq(sm) {
				opacity: 1 !important;
			}
		}
	}
}