@use "variables"as *;

//-- ページ内各content --
.content_block {
  width: 100%;
  padding: 80px 0;

  @include mq(sm) {
    width: 100%;
    padding: 60px 0;
  }
}

.blog_content_block {
  width: 100%;
  padding: 50px 0 120px;

  @include mq(sm) {
    width: 100%;
    padding: 50px 0 100px;
  }
}

.content_inner {
  width: 1200px;
  // padding: 0 50px;
  margin: 0 auto;
  position: relative;

  @include mq(md) {
    width: 85%;
    margin: 0 auto;
  }

  @include mq(sm) {
    width: 85%;
    margin: 0 auto;
    padding: 0;
  }

  .inner_section_block {
    margin-top: 70px;
  }
}

.visual_title {
  padding: 100px 0;
  margin-top: -100px;
  background-attachment: fixed;
  background-size: cover;

  @include mq(sm) {
    background-attachment: unset;
  }

  .visual_title_inner {
    max-width: 980px;
    padding: 0 50px;
    margin: 0 auto;

    @include mq(sm) {
      width: 90%;
      padding: 0;
    }

    h2 {
      font-size: 40px;
      margin: 0;

      @include mq(sm) {
        font-size: 32px;
      }
    }

    p {
      margin-top: 30px;
      font-weight: bold;
    }
  }
}

.height100_img_block {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.two_bgcolor_block {
  background: $gray-lighter;
  position: relative;
  display: flex;
  align-items: center;

  @include mq(sm) {
    flex-wrap: wrap;
  }

  .text_block {
    z-index: 1;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include mq(sm) {
      width: 100%;
      padding: 70px 0;
      background: $gray-light;
    }

    .inner_text_item {
      width: 80%;

      @include mq(sm) {
        width: 90%;
      }
    }
  }

  .height100_img_block {
    width: 50%;

    @include mq(sm) {
      width: 100%;
      height: 250px;
    }
  }
}

.left_bg {
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: $gray-light;
  }

  @include mq(sm) {
    &::before {
      position: static;
      background: none;
    }
  }
}

.right_bg {
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background: $gray-light;
  }

  @include mq(sm) {
    &::before {
      position: static;
      background: none;
    }
  }
}