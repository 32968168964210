@use "variables"as *;



//--header--
header {
	background: $white;
	width: 16.6666666667%;
	height: 100vh;
	position: fixed;
	z-index: 100;
	border-right: solid 0.5px $gray;
	z-index: 10;

	@include mq(md2) {
		height: auto;
		width: 100%;
		background: none;
	}

	@include mq(sm) {
		height: auto;
		width: 100%;
		background: white;
		padding-bottom: 20px;
	}

	.header_pc {
		.header_logo {
			width: 200px;
			text-align: center;
			margin-left: auto;
			margin-right: auto;
			margin-top: 60px;

			@include mq(md2) {
				display: none;
			}

			@include mq(sm) {
				margin-top: 20px;
				margin-left: 20px;
				width: 130px;
			}
		}

	}
}

// sidemenu
.header_item {
	margin-top: 80px;

	@include mq(md2) {
		display: none;
	}

	@include mq(sm) {
		display: none;
	}

	// margin-bottom: 80px;

	.sub-menu {

		// hover
		&:hover {
			.sub-menu-title-product {
				color: $blue;
				opacity: 1;

				&::before {
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					border-top: 7px solid $main-color;
					margin-right: 10px;
					border-bottom: 0;
					padding-left: 0;
				}
			}

			.sub-menu-nav {
				width: 35%;
				transition: .3s ease-out;

				.row {
					transition: 1s ease;
					justify-content: center;
					opacity: 1;
					visibility: visible !important;
				}
			}
		}

		.sub-menu-title {
			width: 200px;
			margin: 0 auto;
			font-size: 20px;
			color: $text-color;
			font-weight: bold;
			display: flex;
			align-items: center;
			position: relative;
			cursor: pointer;
			transition: 0.3s;
			border-bottom: 0.25px solid $gray;
			padding-bottom: 15px;
			padding-top: 15px;

			&:before {
				content: "";
				display: block;
				width: 0;
				height: 0;
				padding-left: 10px;
				border-left: 7px solid $main-color;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
			}

			&:hover {
				opacity: 1;
				color: $blue;
			}

			span {
				font-size: 12px;
				padding-left: 10px;
			}
		}

		.sub-menu-title-product {
			transition: .5s;
		}

		.sub-menu-nav {
			position: fixed;
			background: $gray-lighter;
			top: 0;
			left: 16.6666666667%;
			// left: 260px;
			width: 0;
			height: 100%;
			overflow: hidden;
			transition: .3s ease-out;
			display: grid;
			align-items: center;
			align-content: center;

			.row {
				justify-content: center;
				opacity: 0;
				visibility: hidden;

				>* {
					padding-right: calc(var(--bs-gutter-x) / 1);
					padding-left: calc(var(--bs-gutter-x) / 1);
				}

				li {
					width: 37%;
					margin-bottom: 25px;

					@media screen and (min-width: 1600px) {
						margin-bottom: 10px;
					}



					&:last-child {
						margin-bottom: 0;
						margin-right: 37%;
					}

					img {
						margin-bottom: 5px;

						@include mq(lg) {
							height: 130px;
						}
					}

					a {
						color: $black;
						font-size: 14px;
						font-weight: bold;

						&:hover {
							opacity: 1;
							color: $blue;
						}

					}
				}
			}
		}
	}

	.header-btn {
		margin-top: 80px;
	}
}


// --drawer--
#drawer {
	position: absolute;
	top: 30px;
	right: 20px;
	z-index: 11;

	@include mq(md2) {
		top: 20px;
	}

	@include mq(sm) {
		top: 14px;
	}

	@include mq(lg) {
		display: none;
	}

	label {
		cursor: pointer;
		position: absolute;
		top: 0;
		right: 0;
	}

	#navTgl:checked~.contents_wrapper {
		transform: translateX(-250px);
	}

	/* :::::: toggle button :::::: */
	#navTgl {
		display: none;
	}

	.open {
		z-index: 12;
		position: inherit;
		width: 48px;
		height: 48px;
		transition: background .6s, transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	.open::before,
	.open::after {
		content: "";
	}

	.open span,
	.open::before,
	.open::after {
		content: "";
		position: absolute;
		top: 20px;
		right: 30%;
		width: 40%;
		border-bottom: 3px solid $main-color;
		transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	.open::before {
		transform: translateY(-8px);
	}

	.open::after {
		transform: translateY(8px);
	}

	.close {
		z-index: 3;
		width: 100%;
		height: 100vh;
		pointer-events: none;
		transition: background .6s;
	}

	/* #navTgl:checked + .open {
		transform: translateX(-250px);
	} */
	#navTgl:checked+.open span {
		transform: scaleX(0);
	}

	#navTgl:checked+.open::before {
		transform: rotate(45deg);
		border-bottom: 3px solid $white;
	}

	#navTgl:checked+.open::after {
		transform: rotate(-45deg);
		border-bottom: 3px solid $white;
	}

	#navTgl:checked~.close {
		pointer-events: auto;
		background: rgba(0, 0, 0, .5);
		z-index: 11;
		position: fixed;
	}

	/* :::::: drawer menu :::::: */
	.menu {
		z-index: 11;
		position: fixed;
		overflow: auto;
		top: 0;
		right: 0;
		width: 400px;
		height: 100%;
		margin: 0;
		box-sizing: border-box;
		background: linear-gradient($gradation-deep, $gradation-light);
		transform: translateX(200%);
		transition: transform .5s cubic-bezier(0.215, 0.61, 0.355, 1);

		@include mq(md2) {
			width: 30%;
		}

		@include mq(md) {
			width: 40%;
		}



		@include mq(sm) {
			width: 75%;
		}
	}

	.menu h2,
	.menu a {
		color: $white;
	}

	.menu h2 {
		text-align: center;
	}

	.menu ul {
		margin: 0;
		padding: 0;
	}

	.menu li {
		font-size: .8em;
		line-height: 1.4;
	}

	#navTgl:checked~.menu {
		transform: none;
	}
}

.drawer_inner_btn {
	ul {
		list-style: none;
		margin: 0 0 40px;
		padding: 0;

		li {
			margin-bottom: 15px;

			&:last-of-type {
				margin-bottom: 0;
			}

			a {
				color: $white;
				font-weight: bold;
				background: $text-color;
				border-radius: 20px;
				display: block;
				text-align: center;
				padding: 5px 0;
				text-decoration: none;
			}
		}
	}
}

// .sp_menu {
// 	.header_nav_block {
// 		padding: 0 15px;
// 		margin: 70px 0;

// 		ul {
// 			list-style: none;
// 			padding: 0;

// 			li {
// 				margin-bottom: 16px;

// 				a {
// 					color: $white;
// 					text-decoration: none;
// 					display: flex;
// 					align-items: center;
// 					font-size: 16px;
// 					padding: 5px 15px;

// 					&::before {
// 						font-family: "fontello";
// 						content: "\e803";
// 						font-size: 5px;
// 						margin-right: 10px;
// 						font-weight: normal;
// 					}

// 					&:hover {
// 						background: #424242;
// 						border-radius: 20px;
// 					}

// 					@include mq(sm) {
// 						&:hover {
// 							background: none;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}

// 	.header_tel {
// 		padding: 0 30px;

// 		p {
// 			font-size: 20px;
// 			margin-bottom: 20px;
// 		}
// 	}
// }

.sp_menu {

	// padding-top: 40px;
	.header_nav_block {
		// padding: 0 15px;
		width: 80%;
		margin-top: 80px;
		margin-bottom: 40px;
		margin-left: auto;
		margin-right: auto;

		.link {
			display: block;
			padding-bottom: 20px;
			border-bottom: 1px solid $white;
			margin-top: 20px;
			// transition: ease-in-out 100ms;
			position: relative;
			color: $white;
			font-weight: 700;
			font-size: 20px;
			line-height: 1;
			display: flex;
			align-items: center;

			.jp {
				margin-left: 10px;
				font-family: 'Noto Sans JP', sans-serif;
				font-size: 12px;
				color: $white;
			}
		}

		.contact {
			display: block;
			background: $white;
			margin-top: 40px;
			padding: 20px;
			text-align: center;
			position: relative;
			color: $blue !important;
			font-weight: 700;
			font-size: 20px;
			line-height: 1;
			letter-spacing: 1px;
			display: flex;
			align-items: center;
			justify-content: center;

			&:last-of-type {
				margin-top: 20px;
			}

			// &:before {
			// 	font-size: 20px;
			// 	font-family: "fontello";
			// 	content: "\e801";
			// 	color: #2558c1;
			// 	position: absolute;
			// 	right: 20%;
			// 	top: 50%;
			// 	-webkit-transform: translateY(-50%);
			// 	transform: translateY(-50%);
			// }

			.jp {
				margin-left: 10px;
				font-family: 'Noto Sans JP', sans-serif;
				font-size: 12px;
				color: $blue;
			}

		}
	}

	.header_tel {
		padding: 0 30px;

		p {
			font-size: 20px;
			margin-bottom: 20px;
		}
	}
}

// アコーディオンメニュー
.accordion-wrap {
	padding-bottom: 20px;
	border-bottom: 1px solid $white;
	margin-top: 20px;
}

.accordion-item {
	width: 100%;
	margin: 0 auto;
	cursor: pointer;
	// margin-top: 30px;
}

.accordion-header {
	transition: ease-in-out 100ms;
	position: relative;
	color: $white;
	font-weight: 700;
	font-size: 20px;
	line-height: 1;
	display: flex;
	align-items: center;

	.jp {
		margin-left: 10px;
		font-family: 'Noto Sans JP', sans-serif;
		font-size: 12px;
		color: $white;
	}

}

.accordion-text {
	width: 100%;
	display: none;
	padding-top: 20px;
	padding-bottom: 10px;


	// padding-top: 5px;
	// padding-bottom: 20px;
	ul {
		list-style: none;

		li {
			margin-bottom: 10px;
			margin-left: 20px;

			a {
				font-size: 14px;
				position: relative;

				&:before {
					content: "";
					position: absolute;
					display: inline-block;
					width: 6px;
					height: 6px;
					left: -20px;
					border-top: solid 1.5px #fff;
					border-right: solid 1.5px #fff;
					z-index: 2;
					top: 50%;
					-webkit-transform: translateY(-50%);
					transform: translateY(-50%);
					-webkit-transform: translateY(-50%) rotate(45deg);
					transform: translateY(-50%) rotate(45deg);
				}
			}
		}
	}
}

.fa {
	transition: ease-in-out 300ms;
}

.rotate-fa {
	&::before {
		transform: rotate(310deg) !important;
	}
}


.accordion-header .fa {

	&::before {
		content: "";
		position: absolute;
		display: inline-block;
		width: 6px;
		height: 6px;
		right: 5px;
		border-top: solid 1.5px #fff;
		border-right: solid 1.5px #fff;
		z-index: 2;
		top: 50%;
		transform: translateY(-50%);
		transform: translateY(-50%) rotate(130deg);
	}
}

.accordion-no-bar {
	border-bottom: 0;
}

// JS付与クラス
.change-header-color {
	@include mq(md2) {
		background: $white !important;
		// height: 80px;
		border-bottom: 1px solid $gray;
		padding-bottom: 20px;
	}

	@include mq(sm) {
		background: $white !important;
		padding-bottom: 20px;
		border-bottom: 1px solid $gray;
	}
}

// .change-open-color {
// 	&::before {
// 		border-bottom: 3px solid $white !important;
// 	}

// 	span {
// 		border-bottom: 3px solid $white !important;
// 	}

// 	&::after {
// 		border-bottom: 3px solid $white !important;
// 	}
// }

.display-logo {
	@include mq(md2) {
		display: block !important;
		// content: url(assets/images/common/logo.svg) !important;
		// width: 130px !important;
		// transition: 0.5s all;
		// width: 130px;
		text-align: left !important;
		margin: 0 !important;
		margin-top: 20px !important;
		margin-left: 30px !important;
	}

	@include mq(sm) {
		display: block !important;
		// content: url(assets/images/common/logo.svg) !important;
		// width: 130px !important;
		// transition: 0.5s all;
		// width: 130px;
		text-align: left !important;
		margin: 0 !important;
		margin-top: 20px !important;
		margin-left: 30px !important;
	}

}