@use "variables"as *;

//--下層ページmain_visual--
.main_visual_subpage {
	width: 100%;
	height: 300px;
	background-image: url(assets/images/common/main_visual_sample.jpg);
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;

	@include mq(md) {
		margin-top: 0;
	}

	@include mq(sm) {
		height: 250px;
		margin-top: 0;
	}

	.page_name {
		@include mq(ie) {
			width: 100%;
			text-align: center;
		}

		h1 {
			font-weight: bold;
			font-size: 30px;

			@include mq(sm) {
				font-size: 25px;
			}
		}
	}
}

.mv_sample {
	background-image: url(assets/images/subpage/main_visual_sample2.jpg);
}

.under_content {
	width: 100%;
	padding-top: 80px;

	@include mq(sm) {
		width: 100%;
	}

	.content_inner {
		width: 1100px;
		padding: 0 50px;
		margin: 0 auto;

		@include mq(md) {
			width: 100%;
			margin: 0 auto;
		}

		@include mq(sm) {
			width: 90%;
			margin: 0 auto;
			padding: 0;
		}

		h2 {
			font-size: 28px;
			margin: 0 0 40px;
			padding-bottom: 10px;
			color: $white;
			border-bottom: 7px double $white;
			font-weight: bold;

			@include mq(sm) {
				font-size: 24px;
			}
		}

		h3 {
			margin: 0 0 20px;
			font-size: 23px;
			font-weight: bold;

			@include mq(sm) {
				font-size: 20px;
			}
		}

		h4 {
			font-size: 21px;
			font-weight: bold;
			border-bottom: 1px solid $white;
			padding: 0 0 10px 1em;
			margin-bottom: 10px;
			position: relative;

			&::before {
				font-size: 13px;
				font-family: "fontello";
				content: "\e807";
				color: $white;
				margin-right: 10px;
				position: absolute;
				top: 8px;
				left: 0;
			}

			@include mq(md) {
				font-size: 17px;

				&::before {
					top: 4px;
				}
			}

			@include mq(sm) {
				font-size: 17px;

				&::before {
					top: 4px;
				}
			}
		}
	}
}

// --Gallery--
.gallerypage_block {
	background: $black-light;
	border-radius: 20px;
	padding: 70px 30px 60px;
	margin-bottom: 120px;

	@include mq(sm) {
		padding: 40px 20px;
		margin-bottom: 70px;
	}
}

.gallerypage_inner {
	display: flex;
	flex-wrap: wrap;

	&:last-of-type {
		margin: 0;
	}

	.gallerypage_inner_item {
		margin-right: 20px;
		margin-bottom: 20px;
		width: calc((100% / 3) - (20px * 2 / 3));

		&:nth-child(3n) {
			margin-right: 0;
		}

		@include mq(sm) {
			margin-right: 10px;
			margin-bottom: 10px;
			width: calc((100% / 3) - (10px * 2 / 3));
		}

		img {
			width: 100%;
			height: 100%;
			border-radius: 20px;
			object-fit: cover;
			object-position: center;
			margin-bottom: 10px;
			cursor: pointer;

			@include mq(md) {
				height: auto;
			}

			@include mq(sm) {
				border-radius: 5px;
			}
		}

		p {
			font-size: 13px;

			@include mq(sm) {
				font-size: 16px;
			}
		}
	}
}

// --Member--
.password_block {
	margin-bottom: 120px;

	@include mq(sm) {
		margin-bottom: 70px;
	}

	.content_inner {
		text-align: center;
	}

	p {
		margin-bottom: 20px;
	}

	input[type="password"] {
		color: $main-color;
		width: 50%;
		height: 35px;
		display: block;
		margin: 0 auto;

		&::placeholder {
			color: #575757;
		}

		@include mq(sm) {
			width: 90%;
			font-size: 16px;
		}
	}

	input[type="submit"] {
		background: $red;
		color: $white;
		margin: 0 auto;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		border-radius: 30px;
		width: 250px;
		height: 35px;
	}
}

.memberpage_block {
	background: $black-light;
	border-radius: 20px;
	padding: 70px 50px 60px;
	margin-bottom: 120px;

	@include mq(sm) {
		padding: 40px 20px;
		margin-bottom: 70px;
	}

	.video_block {
		margin-bottom: 70px;

		@include mq(sm) {
			margin-bottom: 40px;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		h3 {
			font-size: 23px;
			font-weight: bold;
			border-bottom: 1px solid $white;
			padding: 0 0 10px 1em;
			margin-bottom: 10px;
			position: relative;

			&::before {
				font-size: 13px;
				font-family: "fontello";
				content: "\e807";
				color: $white;
				margin-right: 10px;
				position: absolute;
				top: 8px;
				left: 0;
			}

			@include mq(sm) {
				font-size: 20px;
			}
		}

		p {
			margin-bottom: 20px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		video {
			width: 100%;
			height: auto;
		}
	}
}

.privacy_block {
	background: $black-light;
	border-radius: 20px;
	padding: 70px 50px 60px;
	margin: 20px 0 120px;

	@include mq(sm) {
		padding: 40px 20px;
		margin: 20px 0 70px;
	}

	p {
		margin-bottom: 20px;
	}

	h4 {
		before {
			content: none;
		}
	}

	section {
		&:last-of-type {
			p {
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
}

// -- メールフォーム --
.form_block {
	margin: 0 auto;
	width: 100%;

	@include mq(sm) {
		padding: 0 20px;
	}

	.form_row {
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px dotted $gray;

		span {
			font-size: 13px;

			@include mq(sm) {
				font-size: 16px;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.form_btn_wrap {
		display: flex;
		align-items: center;
		justify-content: space-around;

		@include mq(sm) {
			.form_btn+.form_btn {
				margin-left: 20px;
			}
		}
	}

	.form_btn {
		display: block;
		width: 300px;
		color: $white;
		padding: 10px 0;
		border: none;
		border-radius: 50px;
		text-align: center;
		background: $main-color;
		margin: 50px auto 0;
		text-decoration: none;
		cursor: pointer;

		&:focus,
		&:hover {
			opacity: 0.8;
		}

		@include mq(sm) {
			font-size: 16px;
			width: 250px;
			margin: 30px auto 0;
		}
	}

	.form_btn[value="back"] {
		background-color: $white;
		border: 1px solid $main-color;
		color: $main-color;
	}

	label {
		display: block;
		margin-bottom: 10px;
		position: static;
	}

	input[type="text"],
	input[type="email"],
	select,
	textarea {
		border: 1px solid $gray;
		border-radius: 3px;
		padding: 10px;

		@include mq(sm) {
			width: 100%;
			font-size: 16px;
		}
	}

	select {
		padding-right: 50px;
	}

	.mwform-radio-field {
		margin-bottom: 13px;
	}

	.required {
		background-color: $orange;
		border-radius: 3px;
		color: $white;
		font-size: 10px;
		margin-left: 10px;
		padding: 2px 10px;
		vertical-align: middle;
		word-break: keep-all;
	}

	.input_long {
		width: 100%;
	}
}

.mw_wp_form_input {
	.input_none {
		display: none;
		speak: none;
	}
}

.mw_wp_form_preview {
	.preview_none {
		display: none;
		speak: none;
	}

	.input_none {
		display: block;
		speak: normal;
	}
}

.mwform-tel-field {
	input[type="text"] {
		display: inline-block;

		@include mq(sm) {
			width: 30% !important;
		}
	}
}

// --会員登録--
/* fieldset {
	margin-bottom: 100px !important;
	.div_text {
		width: 100% !important;
		margin-bottom: 30px !important;
	}
	.button_div {
		width: 100% !important;
		text-align: center !important;
		margin-top: 30px;
		.buttons {
			color: $white;
			width: 200px;
			background: $red;
			border: none;
			height: 40px;
			border-radius: 20px;
			cursor: pointer;
			&:hover {
				opacity: .8;
			}
		}
	}
	.link-text {
		width: 100% !important;
		text-align: center !important;
	}
}
#wpmem_restricted_msg {
	width: 900px;
	margin: 70px auto 0;
	text-align: center;
	display: flex;
	justify-content: center;
	@include mq(sm) {
		width: 80% !important;
	}
}
#wpmem_login_form {
	width: 900px;
	margin: 0 auto !important;
	@include mq(sm) {
		width: 80% !important;
	}
}
#wpmem_login,
#wpmem_reg {
	legend {
		margin-bottom: 30px !important;
		text-align: center;
	}
}
.memberpage_block {
	fieldset {
		margin: 0 !important;
	}
}
.wpmem_msg {
	width: 100% !important;
	margin-bottom: 30px !important;
	border: none !important;
	background: none !important;
	padding: 0 !important;
	p {
		color: $white !important;
	}
} */


// プライバシーポリシー
.page-privacy {
	.privacy_text_block {
		margin-bottom: 40px;

		h2 {
			margin-bottom: 5px;
			font-size: 20px;
		}

		p {
			a {
				font-weight: bold;
				color: $main-color;
			}
		}

		.btn01 {
			margin-top: 20px;
		}
	}
}

// お問い合わせフォーム
// ★調整する必要があるかも
.sub-contact {
	.content_inner {
		width: 70%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 80px;

		@include mq(md) {
			width: 80%;
		}

		@include mq(sm) {
			width: 85%;
		}
	}

	p {
		color: $main-color;
	}

	.note {
		font-size: 14px;

		@include mq(sm) {
			font-size: 14px;
		}
	}

	.fz-16 {
		font-size: 16px;
	}

	.form_block .required {
		background-color: $text-color;
	}

	::placeholder {
		color: $main-color;
	}

	.content_inner .inner_section_block {
		margin-top: 0;
	}

	.mwform-tel-field input[type=text] {

		@include mq(sm) {
			width: 25% !important;
		}
	}

	.form_row_last {
		border: none;

		@include mq(sm) {
			margin-bottom: 0;
		}
	}

	.form_block .form_btn {
		margin: 0;
	}

	.title_block {
		bottom: 60px;

		h2 {
			text-align: center;
			margin-bottom: 20px;
			font-size: 25px;
		}
	}

	label {
		color: $main-color;
		font-size: 16px;
	}


	.align_c {
		font-size: 16px;
	}

	.confirm_text {
		p {
			font-size: 16px;

			@include mq(sm) {
				font-size: 14px !important;
			}
		}
	}

	.home_button {
		a {
			display: block;
			width: 300px;
			color: #fff;
			padding: 15px 0;
			border: none;
			border-radius: 50px;
			text-align: center;
			background: $text-color;
			text-decoration: none;
			cursor: pointer;

			@include mq(sm) {
				width: 250px;
			}
		}

	}

	.mwform-checkbox-field label,
	.mwform-radio-field label {
		display: inline-block;
	}

	.mwform-checkbox-field-text {
		font-size: 16px !important;
		margin-right: 10px;
	}

	select {
		font-size: 16px;
	}

	input[type=text],
	input[type=email],
	.form_row,
	select,
	textarea.input_long,
	.mw_wp_form input[type="file"],
	option {
		color: $main-color;
	}

	.form_btn_wrap {
		margin-top: 40px;
	}

	.mwform-radio-field-text,
	span.mwform-zip-field {
		font-size: 16px !important;
		color: $main-color;
	}
}