@use "variables"as *;

* {
	color: $text-color;
	font-weight: 400;
}

p,
a,
li,
table {
	font-size: 14px;

	@include mq(sm) {
		font-size: 16px;
	}
}

p {
	text-align: justify;
	text-justify: inter-ideograph;
	line-height: 2;
	margin: 0;

	@include mq(sm) {
		line-height: 1.5;
	}
}

h2 {
	font-size: 32px;
	margin: 0 0 40px;
	color: $text-color;
	font-weight: bold;

	@include mq(sm) {
		font-size: 28px;
	}
}

h3 {
	margin: 0 0 20px;
	font-size: 23px;
	font-weight: bold;

	@include mq(sm) {
		font-size: 20px;
	}
}

h4 {
	font-size: 21px;
	font-weight: bold;
	margin-bottom: 10px;

	@include mq(sm) {
		font-size: 18px;
	}
}

.text_link {
	font-weight: bold;
	color: $blue;
}

.fw {
	font-weight: bold;
}

.red_text {
	color: $red;
}

.yellow_text {
	color: $yellow;
}

.pink_text {
	color: $magenta;
}

.under_line {
	font-weight: bold;
	border-bottom: 1px dashed $text-color;
}

.note,
.quote {
	margin-top: 10px;
	font-size: 13px;

	a {
		font-size: 13px;
	}
}

.note {
	position: relative;
	padding-left: 16px;

	&::before {
		content: "※";
		position: absolute;
		left: 0;
		top: 0;
	}
}