// @use "variables"as *;

.pt10 {
	padding-top: 10px;
}

.pt15 {
	padding-top: 15px;
}

.pt20 {
	padding-top: 20px;
}

.pt25 {
	padding-top: 25px;
}

.pt30 {
	padding-top: 30px;
}

.pt35 {
	padding-top: 35px;
}

.pt40 {
	padding-top: 40px;
}

.pt45 {
	padding-top: 45px;
}

.pt50 {
	padding-top: 50px;
}

.pt55 {
	padding-top: 55px;
}

.pt60 {
	padding-top: 60px;
}