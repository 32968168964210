@use "variables"as *;

// -- パンくずリスト --
.breadcrumb_block {
	// width: 880px;
	width: 1200px;
	margin: 10px auto 0;

	@include mq(md) {
		width: 85%;
	}

	@include mq(sm) {
		width: 85%;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;

		@include mq(sm) {
			flex-wrap: nowrap;
			overflow-x: scroll;
		}

		li,
		a {
			color: $text-color;
			font-size: 13px;
		}

		li {
			padding-right: 30px;
			position: relative;

			&::before {
				font-family: "fontello";
				content: "\e800";
				position: absolute;
				right: 10px;
				transform: rotate(90deg);
			}

			&:last-of-type {
				&::before {
					content: "";
				}
			}

			@include mq(sm) {
				white-space: nowrap;
			}
		}
	}
}

//-- メールフォーム --
#mw_wp_form_mw-wp-form-12 {
	p {
		font-size: 16px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.mw_wp_form_preview {
	form {
		p {
			&:nth-of-type(even) {
				border-bottom: 1px dotted #d0d0d0;
				padding-bottom: 20px;
			}
		}
	}
}

.form_item {
	input {
		width: 100%;
		padding: 5px;
		font-size: 16px;
		font-weight: normal;
		margin-bottom: 10px;
	}

	textarea {
		width: 100%;
		padding: 5px;
		font-size: 16px;
		font-weight: normal;
		margin-bottom: 10px;
	}

	.red_text {
		font-size: 14px;
	}
}

.form_item_button {
	display: flex;
	justify-content: center;
	margin-top: 32px;

	@include mq(sm) {
		margin-top: 24px;
	}

	input {
		border: none;
		background: $main-color;
		color: $white;
		font-size: 16px;
		padding: 10px;
		width: 140px;
		line-height: 1;
		border-radius: 5px;
		cursor: pointer;

		@include mq(sm) {
			width: 110px;
		}

		&:hover {

			opacity: 0.8;
		}

		&:nth-of-type(2) {
			margin-left: 20px;
		}
	}
}

.home_button {
	display: flex;
	justify-content: center;
	margin-top: 32px;

	a {
		display: block;
		border: none;
		text-decoration: none;
		background: $main-color;
		color: $white;
		font-size: 16px;
		text-align: center;
		padding: 10px;
		width: 160px;
		line-height: 1;
		border-radius: 5px;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}
	}
}

//-- 電話番号 --
a[href*="tel:"] {
	text-decoration: underline;

	@include mq(md) {
		pointer-events: none;
		cursor: default;
		text-decoration: none;
	}

	@include mq(lg) {
		pointer-events: none;
		cursor: default;
		text-decoration: none;
	}
}

//-- card --
.card_item {
	@include mq(sm) {
		margin-bottom: 40px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	@include mq(ie) {
		padding: 0 12px;
	}

	figure {
		margin: 0;

		img {
			height: auto;
			border-radius: 0.25rem 0.25rem 0 0;
		}
	}
}

.card-body {
	padding: 2rem 1rem;
}

.card_title {
	font-size: 16px;
	margin-bottom: 1rem;
}

.card_text {
	margin-bottom: 1rem;

	@include mq(sm) {
		margin-bottom: 2rem;
	}
}

.blog_post_date {
	font-size: 13px;
	margin-bottom: 10px;
	display: block;
}

.card_button {
	a {
		width: 80%;
		height: 40px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		color: $white;
		font-weight: bold;
		background: $text-color;

		@include mq(sm) {
			width: 200px;
		}
	}
}

//-- background --
.bg_gray-light {
	background: $gray-light;
}

.bg_gray-lighter {
	background: $gray-lighter;
}

//-- flexbox --
.d_flex {
	display: flex;
}

.justify_center {
	justify-content: center;
}

.justify_between {
	justify-content: space-between;
}

.justify_around {
	justify-content: space-around;
}

.align_items_center {
	align-items: center;
}

.sm_order1 {
	@include mq(sm) {
		order: 1;
	}
}

.sm_order2 {
	@include mq(sm) {
		order: 2;
		margin-top: 30px;
	}
}

//-- button --
.header-catalog-btn,
.header-contact-btn,
.main-btn,
.main-btn-white,
.blog-main-btn {
	width: 200px;
	margin-top: 40px;
	margin-left: auto;
	margin-right: auto;

	a {
		// text-align: center;
		// margin: 0 auto;
		width: 200px;
		display: inline-block;
		position: relative;
		transition: all .3s;

		&:before,
		&:after {
			content: "";
			position: absolute;
			display: block;
			top: 50%;
		}

		&:before {
			right: 25px;
			background: $white;
			z-index: 1;
			width: 5px;
			height: 5px;
			border-radius: 4rem;
			transform: translateY(-50%);
			transition: all .5s;
			z-index: 2;
		}

		&:after {
			// left: 71px;
			// content: '';
			right: 0;
			background: $main-color;
			// border: 1.5px solid $main-color;
			z-index: 1;
			width: 55px;
			height: 55px;
			border-radius: 4rem;
			transform: translateY(-50%);
			transition: all .5s;
		}

		&:hover {
			@include mq(xl2) {
				opacity: 1;

				&::before {
					content: "";
					position: absolute;
					display: block;
					top: 50%;
					width: 10px;
					height: 10px;
					right: 20px;
					border-top: solid 2px $white;
					border-right: solid 2px $white;
					z-index: 2;
					border-radius: initial !important;
					background: none;
					-webkit-transform: translateY(-50%) rotate(45deg);
					transform: translateY(-50%) rotate(45deg);
					-webkit-transition: all 0.3s;
					transition: all 0.3s;
				}

				&:after {
					right: -10px;
					width: 65px;
					height: 65px;
					transform: translateY(-50%);
					transition: all .5s;
				}

			}

		}

		span {
			position: relative;
			transition: all .3s;
			z-index: 3;
			color: $black;
			font-weight: bold;
			font-size: 14px;

			@include mq(sm) {
				left: -10px;
			}
		}
	}
}

.header-catalog-btn,
.header-contact-btn {
	a {
		&:before {
			right: 20px;
			width: 0.25rem;
			height: 0.25rem;
		}

		&:after {
			width: 2.8rem;
			height: 2.8rem;
		}

		&:hover {
			@include mq(xl2) {
				opacity: 1;

				&::before {
					width: 8px;
					height: 8px;
					right: 18px;
				}

				&:after {
					right: -9px;
					width: 3.5rem;
					height: 3.5rem;
					transform: translateY(-50%);
					transition: all .5s;
				}

			}

		}
	}
}

.header-catalog-btn {
	a {
		&:after {
			background: $blue;
		}
	}

}

.main-btn,
.main-btn-white {
	width: 165px;
	margin: 0;

	a {
		width: 165px;


	}
}

.blog-main-btn {
	width: 175px;
	margin: 0;

	a {
		width: 175px;
	}
}

.main-btn-white {
	a {
		&:before {
			background: $main-color;
		}

		&:after {
			background: transparent;
			border: 1.5px solid $main-color;
		}

		&:hover {
			@include mq(xl2) {
				opacity: 1;

				&::before {
					border-top: solid 2px $main-color;
					border-right: solid 2px $main-color;
				}
			}


		}
	}
}


.catalog-btn-white,
.contact-btn-white {
	width: 205px;
	display: inline-block;
	position: relative;
	transition: all .3s;

	&:before,
	&:after {
		content: "";
		position: absolute;
		display: block;
		top: 50%;
	}

	&:before {
		right: 25px;
		background: $white;
		z-index: 1;
		width: 5px;
		height: 5px;
		border-radius: 4rem;
		transform: translateY(-50%);
		transition: all .5s;
		z-index: 2;
	}

	&:after {
		right: 0;
		z-index: 1;
		width: 55px;
		height: 55px;
		border-radius: 4rem;
		transform: translateY(-50%);
		transition: all .5s;
		background: transparent;
		border: 1.5px solid $white;
	}



	span {
		color: $white;
	}
}

.catalog-btn-white {
	width: 180px;
}




.btn01 {
	margin-top: 40px;

	a {
		color: $white;
		width: 300px;
		height: 40px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		background: $text-color;

		@include mq(sm) {
			width: 80%;
		}
	}
}

//-- pagenation --
.pagination {
	margin-top: 40px;
	justify-content: center;

	a {
		color: $text-color;
	}

	.page-numbers {
		margin: 0 10px;
	}

	.current {
		border-bottom: 1px solid $text-color;
	}
}

//-- loading --
.loading {
	background: linear-gradient($gradation-deep, $gradation-light);
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 9000;
}

.loading div {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: none;
	z-index: 9999;
	width: 220px;

	@include mq(sm) {
		width: 180px;
	}
}

// -- エレベーターメニュー --
#elevator_nav {
	position: fixed;
	right: 0;
	top: 45%;
	padding: 30px 10px 30px 0;

	@include mq(md) {
		background: none;
		right: 1%;
	}

	@include mq(sm) {
		display: none;
	}

	ul {
		padding: 0;
		list-style: none;
		margin: 0;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			border-left: 1px solid $gray;
			height: 180px;
			top: 10px;
			left: 11px;
			z-index: 0;
		}

		li {
			margin-bottom: 25px;

			&:last-of-type {
				margin-bottom: 0;
				font-size: 13px;
			}

			a {
				text-decoration: none;
				display: flex;
				align-items: center;

				&::before {
					font-size: 13px;
					font-family: "fontello";
					content: "\e807";
					color: $gray;
					width: 24px;
					height: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					z-index: 1;
				}

				span {
					font-size: 13px;
				}
			}
		}
	}
}

.current {
	&::before {
		color: $blue !important;
		font-size: 16px !important;
	}
}

.fadein {
	opacity: 0;
	transform: translate(0, 100px);
	transition: all 1s;
}

.fadein.active {
	opacity: 1;
	transform: translate(0, 0);
}