// @use "variables"as *;

.mb0 {
	margin-bottom: 0 !important;
}

.mb10 {
	margin-bottom: 10px;
}

.mb15 {
	margin-bottom: 15px;
}

.mb20 {
	margin-bottom: 20px;
}

.mb25 {
	margin-bottom: 25px;
}

.mb30 {
	margin-bottom: 30px;
}

.mb35 {
	margin-bottom: 35px;
}

.mb40 {
	margin-bottom: 40px;
}

.mb45 {
	margin-bottom: 45px;
}

.mb50 {
	margin-bottom: 50px;
}

.mb55 {
	margin-bottom: 55px;
}

.mb60 {
	margin-bottom: 60px;
}

.mt20 {
	margin-top: 20px;
}

.mt30 {
	margin-top: 30px;
}

.mt40 {
	margin-top: 40px;
}