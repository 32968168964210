@use "variables"as *;

//--TOPメインビジュアル--
.mv-row {
	--bs-gutter-x: 0rem;
}

.mv-row>* {
	padding: 0;
	margin: 0;
}

.main_visual {
	position: relative;

	.main_visual_inner {
		position: relative;

		.slick-slider {
			margin: 0;
			padding: 0;

			.slick-slide {
				img {
					width: 60%;
					height: 100vh;
					-o-object-fit: cover;
					object-fit: cover;
					-o-object-position: center;
					object-position: center;
					mix-blend-mode: multiply;
					margin-left: 40%;


					@include mq(sm) {
						width: 100% !important;
						margin-left: 0;
						height: 50vh;
					}
				}
			}



			.slick-prev {
				left: 25px;
				z-index: 9;
				width: auto;
				height: auto;

				&::before {
					font-size: 40px;
					font-family: "fontello";
					content: "\e804";
					color: $white;
				}
			}

			.slick-next {
				right: 25px;
				z-index: 9;
				width: auto;
				height: auto;

				&::before {
					font-size: 40px;
					font-family: "fontello";
					content: "\e805";
					color: $white;
				}
			}
		}

		// .slide02 {
		// 	img {
		// 		object-position: right !important;
		// 	}
		// }

		.logo_sp_top {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			position: absolute;
			top: 15%;



			img {
				width: 170px;
			}

			@include mq(md) {
				display: none;
			}

			@include mq(lg) {
				display: none;
			}
		}

		.catch-box {
			width: 40%;
			background: linear-gradient($gradation-deep, $gradation-light);
			position: absolute;
			top: 0;
			bottom: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;


			@include mq(sm) {
				width: 100%;
				// background: none;
				position: relative;
				display: block;
				height: 300px;
				margin-top: -20px;
			}

			.catch_copy {
				position: absolute;
				width: 69%;
				margin-left: auto;
				margin-right: auto;
				text-align: center;

				@include mq(sm) {
					text-align: center;
					position: relative;
					top: 50%;
					transform: translateY(-50%);
				}

				.catch-logo {
					width: 120px;
					margin-left: auto;
					margin-right: auto;

					@include mq(md) {
						width: 80px;
					}

					@include mq(sm) {
						display: none;
					}
				}

				h1 {
					font-size: 38px;
					font-weight: 500;
					line-height: 1.5;
					color: $white;

					@media screen and (min-width: 1401px) and (max-width: 1486px) {
						font-size: 2.5vw !important;
					}


					@include mq(md2) {
						font-size: 2.9vw;
					}

					@include mq(sm) {
						font-size: 25px;
					}
				}

				p {
					font-size: 16px;
					color: $white;
					line-height: 1.5;

					@include mq(sm) {
						font-size: 14px;
					}
				}
			}
		}
	}

	// circle
	.circle {
		position: absolute;
		right: 3%;
		bottom: 5%;

		@include mq(sm) {
			display: none;
			// bottom: 35%;
		}

		&::before {
			content: '↓';
			font-size: 45px;
			color: $blue;
			font-weight: 500;
			position: absolute;
			top: 25%;
			// transform: translateY(-50%);
			left: 35%;
			// transform: translateX(-50%);
		}

		img {
			width: 150px;
			animation: rotate-anime 10s linear infinite;

			@include mq(sm) {
				width: 100px;
			}
		}

		@keyframes rotate-anime {
			0% {
				transform: rotate(0);
			}

			100% {
				transform: rotate(360deg);
			}
		}
	}
}


// Product
.top-products {
	.row {
		justify-content: space-between;

		.top-title {
			margin-bottom: 20px;
		}

		.main-btn {
			margin-left: auto;

			@include mq(sm) {
				margin-bottom: 50px;
			}
		}

	}
}

// products-item
.top-products-item {
	padding-bottom: 0px;

	@include mq(sm) {
		padding-bottom: 0;
	}


	.row {
		justify-content: space-between;
		align-items: center;
		padding-bottom: 80px;
		margin-top: 80px;
		border-bottom: 1px solid $gray;


		@include mq(sm) {
			display: block;
			border: none;
			margin: 0;
			padding: 0;
		}

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
			border-bottom: none;
		}

		.col-md-5 {
			width: 48%;

			@include mq(sm) {
				width: 100%;
				margin-bottom: 40px;
				padding-bottom: 40px;
				border-bottom: 1px solid $gray;
			}

			.item-title {
				margin-bottom: 20px;
			}

			.text {
				margin-top: 20px;
				margin-bottom: 20px;
			}

			.main-btn-white {
				margin-left: auto;
			}

			img {
				height: 350px;
				object-fit: cover;
				object-position: center;

				@include mq(md) {
					height: 250px;
				}

				@include mq(sm) {
					height: 250px;
				}
			}
		}
	}

	.item-row-first {
		@include mq(sm) {
			display: flex;
			border-bottom: 1px solid $gray;
			padding-bottom: 40px;
			margin-bottom: 40px;
		}

		.col-md-5 {
			@include mq(sm) {
				// display: block;
				border-bottom: none;
				padding: 0;
				margin: 0;
			}

			.main-btn-white {
				@include mq(sm) {
					margin-bottom: 40px;
				}
			}
		}

		.text {
			margin-top: 40px;
			margin-bottom: 40px;
		}
	}

	.last-item {
		@include mq(sm) {
			border-bottom: none !important;
			padding-bottom: 0 !important;
			margin-bottom: 0 !important;
		}
	}
}

// text
.flow-text {
	// margin-top: 40px;
	margin-bottom: 40px;
	align-items: center;
	display: flex;
	overflow: hidden;
	width: 100%;
	white-space: nowrap;
	margin-top: 0px;
	z-index: 1;

	@include mq(sm) {
		margin: 0;
	}

	ul {
		animation: flowing 150s linear infinite;
		transform: translateX(100%);
		margin: 0;
		padding: 0;

		li {
			font-size: 250px;
			font-weight: 700;
			color: $blue-light;
			display: inline-block;
			padding-right: 10px;

			@include mq(sm) {
				font-size: 150px;
			}
		}
	}
}


@keyframes flowing {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-100%);
	}
}

// case
.top-cases {
	padding-top: 0px;

	@include mq(sm) {
		padding-top: 20px;
	}

	.blog-main-btn {
		@include mq(sm) {
			margin-left: auto;
			margin-bottom: 40px;
		}
	}

	.top-title {
		@include mq(sm) {
			margin-bottom: 20px;
		}
	}

	.card_item {
		@include mq(sm) {
			padding-bottom: 40px;
			border-bottom: 1px solid $gray;

			&:last-child {
				border: none;
				padding-bottom: 0;
			}
		}
	}

	.no-blog {
		font-size: 16px;
		padding-left: 10px;
	}

	.card {
		border: none;

		.card-body {
			padding: 0;
			margin-bottom: 15px;

			.blog_post_date {
				margin-top: 10px;
				margin-bottom: 0;
				font-size: 14px;
				color: $gray-deep;
			}

			.card_title {
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}

	}

	.category {
		padding: 5px 10px;
		border-radius: 5px;
		border: 1px solid $blue;
		color: $blue;
		font-size: 14px;
		font-weight: 700;
	}
}



// company
.top-company {
	@include mq(sm) {
		// margin-top: 80px;
		// margin-bottom: 80px;
	}

	.row {
		--bs-gutter-x: 0rem;
		--bs-gutter-y: 0;
		// overflow: hidden;
		height: 700px;

		@include mq(sm) {
			height: auto;
		}

		>* {
			padding: 0;
		}

		.bg-gray {
			@include mq(sm) {
				padding-top: 80px;
				padding-bottom: 80px;
			}
		}

		.col-md-6 {
			.img {
				// height: 700px;

				img {
					height: 700px;
					object-fit: cover;
					object-position: center;

					@include mq(sm) {
						height: 300px;
					}
				}
			}
		}

		.company-text {
			width: 70%;
			margin-left: auto;
			margin-right: auto;
			transform: translateY(-50%);
			top: 50%;
			position: relative;
			// padding: 80px;

			.top-title {
				margin-bottom: 0;
			}

			.text {
				margin-top: 40px;
				margin-bottom: 40px;
			}

			.main-btn {
				margin-left: auto;
			}
		}
	}
}

// gallery
.top-gallery {

	ul {
		padding-left: 0;
	}

	// 右から左へ
	@keyframes infinity-scroll-left {
		from {
			transform: translateX(0);
		}

		to {
			transform: translateX(-100%);
		}
	}

	.gallery-wrap {
		display: flex;
		overflow: hidden;

		.gallery-list {
			display: flex;
			list-style: none;
			margin-bottom: 0;

			.gallery-item {
				// width: calc(100vw / 6);
				padding-left: 20px;

				img {
					width: 420px;
					height: 420px;
					object-fit: cover;
					object-position: center;

					@include mq(sm) {
						height: 200px;
						width: 200px;
					}
				}
			}
		}

		.gallery-list--left {
			animation: infinity-scroll-left 30s infinite linear 0.5s both;
		}
	}
}

// news
.top-news {
	padding-top: 0;

	@include mq(sm) {
		padding-top: 0;
	}

	.top-title {
		@include mq(sm) {
			margin-bottom: 20px;
		}
	}

	.blog-main-btn {
		@include mq(sm) {
			margin-left: auto;
			margin-bottom: 50px;
		}
	}

	.news_list {
		display: block;
		width: 100%;
		border: solid 1px $gray;
		border-radius: 20px;
		position: relative;
		margin-bottom: 20px;
		transition: all .5s;

		&:last-child {
			margin-bottom: 0;
		}


		&:before,
		&:after {
			content: "";
			position: absolute;
			display: block;
			top: 50%;


			@include mq(sm) {
				display: none;
			}
		}

		&:before {
			right: 65px;
			background: $blue;
			z-index: 1;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			transform: translateY(-50%);
			transition: all .5s;
			z-index: 2;

			// @include mq(sm) {
			// 	right: 25px;
			// 	width: 3px;
			// 	height: 3px;
			// }
		}

		&:after {
			border: 1px solid $blue;
			right: 40px;
			z-index: 1;
			width: 55px;
			height: 55px;
			border-radius: 50%;
			transform: translateY(-50%);
			transition: all .5s;

			// @include mq(sm) {
			// 	right: 10px;
			// 	width: 40px;
			// 	height: 40px;
			// }
		}

		&:hover {
			@include mq(xl2) {
				opacity: 1;
				border: solid 1px $blue;

				&::before {
					content: "";
					position: absolute;
					display: block;
					top: 50%;
					width: 10px;
					height: 10px;
					right: 70px;

					border-top: solid 2px $white;
					border-right: solid 2px $white;
					z-index: 2;
					border-radius: initial !important;
					background: none;
					-webkit-transform: translateY(-50%) rotate(45deg);
					transform: translateY(-50%) rotate(45deg);
					-webkit-transition: all 0.3s;
					transition: all 0.3s;
				}

				&:after {
					// right: 10px;
					border: none;
					background: $blue;
					width: 65px;
					height: 65px;
					transform: translateY(-50%);
					transition: all .5s;
				}
			}

		}

		dl {
			padding: 25px 40px;
			margin-bottom: 0;

			@include mq(sm) {
				padding: 15px 20px;
			}

			dt {
				time {
					font-size: 14px;
					font-weight: 700;
					color: $blue;
				}
			}

			.news-title {
				font-size: 16px;
				font-weight: 500;
			}
		}
	}

}

// top-contact-catalog
.top-contact-catalog {
	.row {
		--bs-gutter-x: 0rem;
		--bs-gutter-y: 0;
		// overflow: hidden;
		height: 400px;

		>* {
			padding: 0;
		}

		.top-title-white {
			font-size: 60px;

			@include mq(md) {
				font-size: 6vw;
			}

			@include mq(sm) {
				font-size: 40px;
				margin-bottom: 20px;
			}
		}

		.japanese-white {
			font-size: 12px;
		}

		.col-contact {
			background-color: $main-color;
			border-right: 1px solid $white;
			position: relative;
			transition: all 1s;

			&:hover {
				@include mq(xl2) {
					background-image: url(assets/images/top/top-contact.jpg);
					background-position: center;
					background-size: cover;
					transition: all 1s;
					position: relative;

					&::before {
						content: '';
						background-color: rgba(0, 0, 0, 0.5);
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						display: block;
					}
				}
			}
		}

		.col-catalog {
			position: relative;
			background-color: $blue;
			transition: all 1s;

			&:hover {
				@include mq(xl2) {
					background-image: url(assets/images/top/top-catalog.jpg);
					background-position: center;
					background-size: cover;
					transition: all 1s;
					position: relative;

					&::before {
						content: '';
						background-color: rgba(0, 0, 0, 0.5);
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						display: block;
					}
				}
			}
		}

		.col-contact-inner,
		.col-catalog-inner {

			position: relative;
			display: block;
			height: 100%;

			&:hover {
				@include mq(xl2) {
					opacity: 1;

					.contact-btn-white,
					.catalog-btn-white {

						position: relative;
						margin-top: 40px;
						margin-left: auto;
						display: inherit;

						@include mq(sm) {
							margin-top: 0 !important;
						}

						&::before {
							content: "";
							position: absolute;
							display: block;
							top: 50%;
							width: 10px;
							height: 10px;
							right: 20px;
							border-top: solid 2px $white;
							border-right: solid 2px $white;
							z-index: 2;
							border-radius: initial !important;
							background: none;
							-webkit-transform: translateY(-50%) rotate(45deg);
							transform: translateY(-50%) rotate(45deg);
							-webkit-transition: all 0.3s;
							transition: all 0.3s;
						}

						&:after {
							right: -10px;
							width: 65px;
							height: 65px;
							transform: translateY(-50%);
							transition: all .5s;
							background: $white;
						}
					}


					.contact-btn-white {
						&::before {
							border-top: solid 2px $main-color;
							border-right: solid 2px $main-color;
						}
					}

					.catalog-btn-white {
						&::before {
							border-top: solid 2px $blue;
							border-right: solid 2px $blue;
						}

					}
				}

			}


			.box {
				position: relative;
				height: auto;
				transform: translateY(-50%);
				top: 50%;
				width: 70%;
				margin-left: auto;
				margin-right: auto;
			}
		}



		.contact-btn-white,
		.catalog-btn-white {
			position: relative;
			margin-top: 40px;
			margin-left: auto;
			display: inherit;

			@include mq(sm) {
				font-size: 14px;
				margin-top: 0 !important;
			}
		}

	}
}










//--news_block--
.news_block {
	width: 100%;
	background: $gray-lighter;

	.news_block_inner {
		width: 980px;
		margin: 0 auto;
		padding: 40px;
		display: flex;
		align-items: center;

		@include mq(md) {
			width: 100%;
		}

		@include mq(sm) {
			width: 90%;
			flex-wrap: wrap;
		}

		.news_heading {
			width: 20%;

			@include mq(sm) {
				width: 100%;
			}

			h3 {
				text-align: center;
				color: $text-color;
				font-size: 24px;
				margin: 0;
			}

			.archive_btn {
				margin: 15px 0 0;
				text-align: center;

				a {
					text-decoration: none;
					color: $white;
					background: $main-color;
					padding: 2px 15px 3px;
					border-radius: 20px;
					font-size: 12px;
				}
			}
		}

		.news_list {
			padding: 0 0 0 30px;
			width: 90%;

			@include mq(sm) {
				width: 100%;
				margin: 0;
				padding: 0;
			}

			dl {
				border-bottom: 1px solid $gray;
				margin: 0;
				padding: 10px 0;
				display: flex;
				align-items: center;

				@include mq(sm) {
					padding: 20px 0;
					flex-wrap: wrap;
				}

				&:last-of-type {
					border-bottom: 0;
				}

				dt {
					font-size: 12px;
					color: $main-color;
					margin-right: 20px;

					@include mq(sm) {
						width: 100%;
					}

					time {
						color: $main-color;
						font-weight: bold;
					}
				}

				dd {
					margin: 0;

					@include mq(sm) {
						width: 100%;
					}

					a {
						color: $text-color;
						font-size: 14px;
						font-weight: bold;

						@include mq(sm) {
							font-size: 16px;
						}

						&:hover {
							@include mq(xl2) {
								color: $text-color;
								opacity: .8;
							}

						}
					}
				}
			}
		}

		.archive_btn_sp {
			margin: 10px auto;

			@include mq(sm) {
				width: 250px;
				margin: 30px auto 0;
			}

			@include mq(md) {
				display: none;
			}

			@include mq(lg) {
				display: none;
			}

			a {
				text-decoration: none;
				color: $white;
				background: $main-color;
				padding: 10px 0;
				border-radius: 20px;
				font-size: 16px;
				display: block;
				text-align: center;
			}
		}
	}
}

.carousel_slider {
	.slick-slider {
		cursor: pointer;

		.slick-track {
			display: flex;
			justify-content: space-between;
		}

		.slick-slide {
			margin-right: 10px;

			img {
				border-radius: 0.25rem;
			}
		}

		.slick-list {
			margin-right: -10px;
		}

		.slick-prev {
			left: 25px;
			top: 47%;
			z-index: 9;

			&::before {
				font-size: 40px;
				font-family: "fontello";
				content: "\e804";
				color: $white;
				filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));

				@include mq(sm) {
					font-size: 30px;
				}
			}

			@include mq(sm) {
				left: 10px;
			}
		}

		.slick-next {
			right: 25px;
			top: 47%;
			z-index: 9;

			&::before {
				font-size: 40px;
				font-family: "fontello";
				content: "\e805";
				color: $white;
				filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));

				@include mq(sm) {
					font-size: 30px;
				}
			}

			@include mq(sm) {
				right: 10px;
			}
		}
	}
}

.galleryList {
	width: 100%;
}

.modalBg {
	background-color: rgba(0, 0, 0, .8);
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;
}

.modalContent {
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	bottom: 0;
	width: 100%;
	z-index: 101;
	cursor: pointer;
}

.modalImg {
	position: relative;
	width: 800px;
}

.modalImg .closeBtn {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 103;
	border: 0;
	background: $main-color;
	border-radius: 0;
	padding: 10px;
	cursor: pointer;
	outline: none;

	i {
		color: $white;
		font-size: 20px;
		line-height: 1;
	}
}

// -- visual_title --
#top_content02 {
	.visual_title {
		background-image: url(assets/images/top/visual_title_sample1.jpg);
		background-color: #ccc;
		background-blend-mode: multiply;

		h2 {
			color: $white;
		}

		p {
			color: $white;
		}
	}
}