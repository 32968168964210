/*
Theme Name : design_template
*/
@use "variables"as *;

* {
	font-family: 'Noto Sans JP', "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
	box-sizing: border-box;
}

html {
	height: 100%;

	@include mq(sm) {
		-webkit-overflow-scrolling: touch;
	}
}

img {
	width: 100%;
}

h2,
h3,
h4 {
	margin: 0;
}

a {
	text-decoration: none;

	&:hover {
		opacity: 0.8;
	}
}

main {
	@include mq(md2) {
		width: 100% !important;
	}

	@include mq(sm) {
		width: 100% !important;
	}
}

.contents_wrapper {
	position: relative;
	transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);

	@include mq(sm) {
		overflow: hidden;
	}
}

@media screen and (max-width: 750px) {
	body {
		min-width: initial;
	}
}


// 共通
.sec-01 {
	padding-top: 120px;
	padding-bottom: 120px;

	@include mq(sm) {
		padding-top: 80px;
		padding-bottom: 80px;
	}

	.sec-01-inner {
		width: 85%;
		margin-left: auto;
		margin-right: auto;
	}
}

.bg-gray {
	background-color: $gray-lighter;
}

.top-title,
.top-title-white {
	color: $main-color;
	font-size: 85px;
	font-weight: bold;

	@include mq(md2) {
		font-size: 7.5vw;
	}

	@include mq(md) {
		font-size: 7vw;
	}

	@include mq(sm) {
		font-size: 55px;
	}

}

.top-title-white {
	color: $white;
}

.japanese,
.japanese-white {
	font-size: 18px;
	font-weight: 500;
	position: relative;
	padding-left: 15px;

	&:before {
		content: "";
		position: absolute;
		display: block;
		top: 50%;
		transform: translateY(-50%);
		left: 0px;
		background: $main-color;
		z-index: 1;
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 4rem;
		z-index: 2;
	}

	@include mq(sm) {
		font-size: 14px;
	}
}

.japanese-white {
	color: $white;

	&::before {
		background: $white;
	}

}

.title-text {
	font-size: 20px;
	margin-bottom: 20px;
}

.text {
	font-size: 16px;
}

// 商品タイトルのスタイル
.item-title {
	display: flex;
	font-size: 30px;
	font-weight: 500;
	margin-bottom: 0;

	@include mq(md2) {
		font-size: 2vw;
	}

	@include mq(md) {
		font-size: 2vw;
	}

	@include mq(sm) {
		font-size: 5vw;
	}

	span {
		font-family: 'Figtree',
			sans-serif;
		font-weight: 700;
		color: $main-color;
		padding-right: 15px;
		font-size: 45px;
		position: relative;

		@include mq(sm) {
			font-size: 30px;
		}

		&::before {
			content: 'Products';
			position: absolute;
			font-size: 12px;
			top: -10px;

			@include mq(sm) {
				font-size: 10px;
			}

		}
	}
}

.title-row {
	align-items: center;
}


// 電話
.tel {
	text-decoration: none !important;
	color: $gray-deep;

	@media(min-width: 768px) {
		pointer-events: none;
	}
}