@use "variables"as *;

.sm_none {
    @include mq(sm) {
        display: none;
    }
}

.md_none {
    @include mq(md) {
        display: none;
    }
}

.lg_none {
    @include mq(lg) {
        display: none;
    }
}