.align_c {
	text-align: center;
}

.align_l {
	text-align: left;
}

.align_r {
	text-align: right;
}