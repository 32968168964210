@charset "UTF-8";
/*
Theme Name : design_template
*/
* {
  font-family: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  box-sizing: border-box;
}

html {
  height: 100%;
}
@media screen and (max-width: 767px) {
  html {
    -webkit-overflow-scrolling: touch;
  }
}

img {
  width: 100%;
}

h2,
h3,
h4 {
  margin: 0;
}

a {
  text-decoration: none;
}
a:hover {
  opacity: 0.8;
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  main {
    width: 100% !important;
  }
}
@media screen and (max-width: 767px) {
  main {
    width: 100% !important;
  }
}

.contents_wrapper {
  position: relative;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media screen and (max-width: 767px) {
  .contents_wrapper {
    overflow: hidden;
  }
}

@media screen and (max-width: 750px) {
  body {
    min-width: initial;
  }
}
.sec-01 {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media screen and (max-width: 767px) {
  .sec-01 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.sec-01 .sec-01-inner {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.bg-gray {
  background-color: #f7f7f7;
}

.top-title,
.top-title-white {
  color: #070EF3;
  font-size: 85px;
  font-weight: bold;
}
@media screen and (min-width: 768px) and (max-width: 1400px) {
  .top-title,
  .top-title-white {
    font-size: 7.5vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .top-title,
  .top-title-white {
    font-size: 7vw;
  }
}
@media screen and (max-width: 767px) {
  .top-title,
  .top-title-white {
    font-size: 55px;
  }
}

.top-title-white {
  color: #fff;
}

.japanese,
.japanese-white {
  font-size: 18px;
  font-weight: 500;
  position: relative;
  padding-left: 15px;
}
.japanese:before,
.japanese-white:before {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  background: #070EF3;
  z-index: 1;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 4rem;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .japanese,
  .japanese-white {
    font-size: 14px;
  }
}

.japanese-white {
  color: #fff;
}
.japanese-white::before {
  background: #fff;
}

.title-text {
  font-size: 20px;
  margin-bottom: 20px;
}

.text {
  font-size: 16px;
}

.item-title {
  display: flex;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) and (max-width: 1400px) {
  .item-title {
    font-size: 2vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .item-title {
    font-size: 2vw;
  }
}
@media screen and (max-width: 767px) {
  .item-title {
    font-size: 5vw;
  }
}
.item-title span {
  font-family: "Figtree", sans-serif;
  font-weight: 700;
  color: #070EF3;
  padding-right: 15px;
  font-size: 45px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .item-title span {
    font-size: 30px;
  }
}
.item-title span::before {
  content: "Products";
  position: absolute;
  font-size: 12px;
  top: -10px;
}
@media screen and (max-width: 767px) {
  .item-title span::before {
    font-size: 10px;
  }
}

.title-row {
  align-items: center;
}

.tel {
  text-decoration: none !important;
  color: #818181;
}
@media (min-width: 768px) {
  .tel {
    pointer-events: none;
  }
}

.content_block {
  width: 100%;
  padding: 80px 0;
}
@media screen and (max-width: 767px) {
  .content_block {
    width: 100%;
    padding: 60px 0;
  }
}

.blog_content_block {
  width: 100%;
  padding: 50px 0 120px;
}
@media screen and (max-width: 767px) {
  .blog_content_block {
    width: 100%;
    padding: 50px 0 100px;
  }
}

.content_inner {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .content_inner {
    width: 85%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 767px) {
  .content_inner {
    width: 85%;
    margin: 0 auto;
    padding: 0;
  }
}
.content_inner .inner_section_block {
  margin-top: 70px;
}

.visual_title {
  padding: 100px 0;
  margin-top: -100px;
  background-attachment: fixed;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  .visual_title {
    background-attachment: unset;
  }
}
.visual_title .visual_title_inner {
  max-width: 980px;
  padding: 0 50px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .visual_title .visual_title_inner {
    width: 90%;
    padding: 0;
  }
}
.visual_title .visual_title_inner h2 {
  font-size: 40px;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .visual_title .visual_title_inner h2 {
    font-size: 32px;
  }
}
.visual_title .visual_title_inner p {
  margin-top: 30px;
  font-weight: bold;
}

.height100_img_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.two_bgcolor_block {
  background: #f7f7f7;
  position: relative;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .two_bgcolor_block {
    flex-wrap: wrap;
  }
}
.two_bgcolor_block .text_block {
  z-index: 1;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .two_bgcolor_block .text_block {
    width: 100%;
    padding: 70px 0;
    background: #e2e2e2;
  }
}
.two_bgcolor_block .text_block .inner_text_item {
  width: 80%;
}
@media screen and (max-width: 767px) {
  .two_bgcolor_block .text_block .inner_text_item {
    width: 90%;
  }
}
.two_bgcolor_block .height100_img_block {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .two_bgcolor_block .height100_img_block {
    width: 100%;
    height: 250px;
  }
}

.left_bg::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: #e2e2e2;
}
@media screen and (max-width: 767px) {
  .left_bg::before {
    position: static;
    background: none;
  }
}

.right_bg::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: #e2e2e2;
}
@media screen and (max-width: 767px) {
  .right_bg::before {
    position: static;
    background: none;
  }
}

*, ::after, ::before {
  box-sizing: border-box;
}

::after, ::before {
  text-decoration: inherit;
  vertical-align: inherit;
}

html {
  cursor: default;
  line-height: 1.5;
  -moz-tab-size: 4;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  word-break: break-word;
}

body {
  margin: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

dl dl, dl ol, dl ul, ol dl, ul dl {
  margin: 0;
}

ol ol, ol ul, ul ol, ul ul {
  margin: 0;
}

hr {
  height: 0;
  overflow: visible;
}

main {
  display: block;
}

nav ol, nav ul {
  list-style: none;
  padding: 0;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

iframe {
  border-style: none;
}

img {
  border-style: none;
}

svg:not([fill]) {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

button, input, select {
  margin: 0;
}

button {
  overflow: visible;
  text-transform: none;
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button;
}

fieldset {
  border: 1px solid #a0a0a0;
  padding: 0.35em 0.75em 0.625em;
}

input {
  overflow: visible;
}

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

select {
  text-transform: none;
}

textarea {
  margin: 0;
  overflow: auto;
  resize: vertical;
}

[type=checkbox], [type=radio] {
  padding: 0;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

details {
  display: block;
}

dialog {
  background-color: #fff;
  border: solid;
  color: #000;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[tabindex], a, area, button, input, label, select, summary, textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

[hidden] {
  display: none;
}

[aria-busy=true] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled=true], [disabled] {
  cursor: not-allowed;
}

[aria-hidden=false][hidden] {
  display: initial;
}

[aria-hidden=false][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

header {
  background: #fff;
  width: 16.6666666667%;
  height: 100vh;
  position: fixed;
  z-index: 100;
  border-right: solid 0.5px rgba(0, 0, 0, 0.125);
  z-index: 10;
}
@media screen and (min-width: 768px) and (max-width: 1400px) {
  header {
    height: auto;
    width: 100%;
    background: none;
  }
}
@media screen and (max-width: 767px) {
  header {
    height: auto;
    width: 100%;
    background: white;
    padding-bottom: 20px;
  }
}
header .header_pc .header_logo {
  width: 200px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}
@media screen and (min-width: 768px) and (max-width: 1400px) {
  header .header_pc .header_logo {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  header .header_pc .header_logo {
    margin-top: 20px;
    margin-left: 20px;
    width: 130px;
  }
}

.header_item {
  margin-top: 80px;
}
@media screen and (min-width: 768px) and (max-width: 1400px) {
  .header_item {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .header_item {
    display: none;
  }
}
.header_item .sub-menu:hover .sub-menu-title-product {
  color: #1068FF;
  opacity: 1;
}
.header_item .sub-menu:hover .sub-menu-title-product::before {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid #070EF3;
  margin-right: 10px;
  border-bottom: 0;
  padding-left: 0;
}
.header_item .sub-menu:hover .sub-menu-nav {
  width: 35%;
  transition: 0.3s ease-out;
}
.header_item .sub-menu:hover .sub-menu-nav .row {
  transition: 1s ease;
  justify-content: center;
  opacity: 1;
  visibility: visible !important;
}
.header_item .sub-menu .sub-menu-title {
  width: 200px;
  margin: 0 auto;
  font-size: 20px;
  color: #1e1e1e;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  border-bottom: 0.25px solid rgba(0, 0, 0, 0.125);
  padding-bottom: 15px;
  padding-top: 15px;
}
.header_item .sub-menu .sub-menu-title:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  padding-left: 10px;
  border-left: 7px solid #070EF3;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
.header_item .sub-menu .sub-menu-title:hover {
  opacity: 1;
  color: #1068FF;
}
.header_item .sub-menu .sub-menu-title span {
  font-size: 12px;
  padding-left: 10px;
}
.header_item .sub-menu .sub-menu-title-product {
  transition: 0.5s;
}
.header_item .sub-menu .sub-menu-nav {
  position: fixed;
  background: #f7f7f7;
  top: 0;
  left: 16.6666666667%;
  width: 0;
  height: 100%;
  overflow: hidden;
  transition: 0.3s ease-out;
  display: grid;
  align-items: center;
  align-content: center;
}
.header_item .sub-menu .sub-menu-nav .row {
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}
.header_item .sub-menu .sub-menu-nav .row > * {
  padding-right: calc(var(--bs-gutter-x) / 1);
  padding-left: calc(var(--bs-gutter-x) / 1);
}
.header_item .sub-menu .sub-menu-nav .row li {
  width: 37%;
  margin-bottom: 25px;
}
@media screen and (min-width: 1600px) {
  .header_item .sub-menu .sub-menu-nav .row li {
    margin-bottom: 10px;
  }
}
.header_item .sub-menu .sub-menu-nav .row li:last-child {
  margin-bottom: 0;
  margin-right: 37%;
}
.header_item .sub-menu .sub-menu-nav .row li img {
  margin-bottom: 5px;
}
@media screen and (min-width: 1401px) {
  .header_item .sub-menu .sub-menu-nav .row li img {
    height: 130px;
  }
}
.header_item .sub-menu .sub-menu-nav .row li a {
  color: #1e1e1e;
  font-size: 14px;
  font-weight: bold;
}
.header_item .sub-menu .sub-menu-nav .row li a:hover {
  opacity: 1;
  color: #1068FF;
}
.header_item .header-btn {
  margin-top: 80px;
}

#drawer {
  position: absolute;
  top: 30px;
  right: 20px;
  z-index: 11;
  /* :::::: toggle button :::::: */
  /* #navTgl:checked + .open {
  	transform: translateX(-250px);
  } */
  /* :::::: drawer menu :::::: */
}
@media screen and (min-width: 768px) and (max-width: 1400px) {
  #drawer {
    top: 20px;
  }
}
@media screen and (max-width: 767px) {
  #drawer {
    top: 14px;
  }
}
@media screen and (min-width: 1401px) {
  #drawer {
    display: none;
  }
}
#drawer label {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}
#drawer #navTgl:checked ~ .contents_wrapper {
  transform: translateX(-250px);
}
#drawer #navTgl {
  display: none;
}
#drawer .open {
  z-index: 12;
  position: inherit;
  width: 48px;
  height: 48px;
  transition: background 0.6s, transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#drawer .open::before,
#drawer .open::after {
  content: "";
}
#drawer .open span,
#drawer .open::before,
#drawer .open::after {
  content: "";
  position: absolute;
  top: 20px;
  right: 30%;
  width: 40%;
  border-bottom: 3px solid #070EF3;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#drawer .open::before {
  transform: translateY(-8px);
}
#drawer .open::after {
  transform: translateY(8px);
}
#drawer .close {
  z-index: 3;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  transition: background 0.6s;
}
#drawer #navTgl:checked + .open span {
  transform: scaleX(0);
}
#drawer #navTgl:checked + .open::before {
  transform: rotate(45deg);
  border-bottom: 3px solid #fff;
}
#drawer #navTgl:checked + .open::after {
  transform: rotate(-45deg);
  border-bottom: 3px solid #fff;
}
#drawer #navTgl:checked ~ .close {
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;
  position: fixed;
}
#drawer .menu {
  z-index: 11;
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  background: linear-gradient(#0308C1, #65ACFE);
  transform: translateX(200%);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media screen and (min-width: 768px) and (max-width: 1400px) {
  #drawer .menu {
    width: 30%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  #drawer .menu {
    width: 40%;
  }
}
@media screen and (max-width: 767px) {
  #drawer .menu {
    width: 75%;
  }
}
#drawer .menu h2,
#drawer .menu a {
  color: #fff;
}
#drawer .menu h2 {
  text-align: center;
}
#drawer .menu ul {
  margin: 0;
  padding: 0;
}
#drawer .menu li {
  font-size: 0.8em;
  line-height: 1.4;
}
#drawer #navTgl:checked ~ .menu {
  transform: none;
}

.drawer_inner_btn ul {
  list-style: none;
  margin: 0 0 40px;
  padding: 0;
}
.drawer_inner_btn ul li {
  margin-bottom: 15px;
}
.drawer_inner_btn ul li:last-of-type {
  margin-bottom: 0;
}
.drawer_inner_btn ul li a {
  color: #fff;
  font-weight: bold;
  background: #1e1e1e;
  border-radius: 20px;
  display: block;
  text-align: center;
  padding: 5px 0;
  text-decoration: none;
}

.sp_menu .header_nav_block {
  width: 80%;
  margin-top: 80px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}
.sp_menu .header_nav_block .link {
  display: block;
  padding-bottom: 20px;
  border-bottom: 1px solid #fff;
  margin-top: 20px;
  position: relative;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  display: flex;
  align-items: center;
}
.sp_menu .header_nav_block .link .jp {
  margin-left: 10px;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
  color: #fff;
}
.sp_menu .header_nav_block .contact {
  display: block;
  background: #fff;
  margin-top: 40px;
  padding: 20px;
  text-align: center;
  position: relative;
  color: #1068FF !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sp_menu .header_nav_block .contact:last-of-type {
  margin-top: 20px;
}
.sp_menu .header_nav_block .contact .jp {
  margin-left: 10px;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
  color: #1068FF;
}
.sp_menu .header_tel {
  padding: 0 30px;
}
.sp_menu .header_tel p {
  font-size: 20px;
  margin-bottom: 20px;
}

.accordion-wrap {
  padding-bottom: 20px;
  border-bottom: 1px solid #fff;
  margin-top: 20px;
}

.accordion-item {
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
}

.accordion-header {
  transition: ease-in-out 100ms;
  position: relative;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  display: flex;
  align-items: center;
}
.accordion-header .jp {
  margin-left: 10px;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
  color: #fff;
}

.accordion-text {
  width: 100%;
  display: none;
  padding-top: 20px;
  padding-bottom: 10px;
}
.accordion-text ul {
  list-style: none;
}
.accordion-text ul li {
  margin-bottom: 10px;
  margin-left: 20px;
}
.accordion-text ul li a {
  font-size: 14px;
  position: relative;
}
.accordion-text ul li a:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 6px;
  height: 6px;
  left: -20px;
  border-top: solid 1.5px #fff;
  border-right: solid 1.5px #fff;
  z-index: 2;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}

.fa {
  transition: ease-in-out 300ms;
}

.rotate-fa::before {
  transform: rotate(310deg) !important;
}

.accordion-header .fa::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 6px;
  height: 6px;
  right: 5px;
  border-top: solid 1.5px #fff;
  border-right: solid 1.5px #fff;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  transform: translateY(-50%) rotate(130deg);
}

.accordion-no-bar {
  border-bottom: 0;
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .change-header-color {
    background: #fff !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .change-header-color {
    background: #fff !important;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .display-logo {
    display: block !important;
    text-align: left !important;
    margin: 0 !important;
    margin-top: 20px !important;
    margin-left: 30px !important;
  }
}
@media screen and (max-width: 767px) {
  .display-logo {
    display: block !important;
    text-align: left !important;
    margin: 0 !important;
    margin-top: 20px !important;
    margin-left: 30px !important;
  }
}

footer {
  width: 83.3333333333%;
  margin-left: auto;
  border-top: 1px solid #e2e2e2;
  padding-top: 120px;
}
@media screen and (min-width: 768px) and (max-width: 1400px) {
  footer {
    width: 100% !important;
  }
}
@media screen and (max-width: 767px) {
  footer {
    width: 100% !important;
    padding-top: 80px;
  }
}
footer .footer_inner .row {
  align-items: center;
  justify-content: space-between;
  --bs-gutter-x: 0rem;
  --bs-gutter-y: 0;
  margin-bottom: 110px;
}
@media screen and (max-width: 767px) {
  footer .footer_inner .row {
    margin-bottom: 80px;
  }
}
footer .footer_inner .row > * {
  padding: 0;
}
footer .footer_inner .row .footer_nav,
footer .footer_inner .row .footer_shopinfo_text {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 767px) {
  footer .footer_inner .row .footer_nav,
  footer .footer_inner .row .footer_shopinfo_text {
    width: 85%;
  }
}
footer .footer_inner .row .footer_shopinfo_text .footer_logo img {
  width: 250px;
  height: 100%;
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  footer .footer_inner .row .footer_shopinfo_text .footer_logo img {
    width: 200px;
  }
}
footer .footer_inner .row .footer_shopinfo_text p {
  font-size: 16px;
  color: #818181;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  footer .footer_inner .row .footer_shopinfo_text p {
    font-size: 14px;
  }
}
footer .footer_inner .row .footer_nav {
  width: 80%;
}
footer .footer_inner .row .footer_nav .footer-nav-link {
  border-bottom: 1px solid #818181;
  padding-bottom: 5px;
  margin-bottom: 5px;
  color: #818181;
  display: block;
  font-size: 14px;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  footer .footer_inner .row .footer_nav .footer-nav-link {
    margin-top: 40px;
  }
}
footer .footer_inner .row .footer_nav ul {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  footer .footer_inner .row .footer_nav ul:last-child {
    margin-bottom: 0;
  }
}
footer .footer_inner .row .footer_nav ul li {
  margin-top: 10px;
  margin-bottom: 10px;
}
footer .footer_inner .row .footer_nav ul li a {
  color: #818181;
  padding-right: 25px;
  font-size: 14px;
}
footer .footer_inner .row .footer_nav .product-link {
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  footer .footer_inner .row .footer_nav .product-link {
    margin-bottom: 20px;
  }
}
footer .footer_inner .row .footer_nav .product-link li a {
  position: relative;
  padding-left: 15px;
}
footer .footer_inner .row .footer_nav .product-link li a::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  padding-left: 10px;
  border-left: 6px solid #070EF3;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.copy {
  text-align: center;
  padding: 15px 0;
  background: rgba(0, 0, 0, 0.125);
}
.copy small {
  font-size: 12px;
}
@media screen and (max-width: 767px) {
  .copy {
    padding: 20px 0 90px;
  }
}

.pagetop_block {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9;
}
@media screen and (max-width: 767px) {
  .pagetop_block {
    display: flex;
    flex-wrap: wrap;
  }
}
.pagetop_block .contact_sp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .pagetop_block .contact_sp {
    display: none !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1400px) {
  .pagetop_block .contact_sp {
    display: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .pagetop_block .contact_sp {
    display: none !important;
  }
}
.pagetop_block .contact_sp a {
  background: #1068FF;
  padding: 10px 30px;
  height: 60px;
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
}
.pagetop_block .contact_sp a::before {
  font-family: "fontello";
  content: "\e801";
  font-size: 16px;
  margin-right: 10px;
  font-weight: normal;
}
@media screen and (max-width: 767px) {
  .pagetop_block .contact_sp a {
    opacity: 1 !important;
  }
}
.pagetop_block #pagetop {
  width: 60px;
  height: 60px;
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .pagetop_block #pagetop {
    width: 20%;
    height: 60px;
  }
}
.pagetop_block #pagetop a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #070EF3;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #fff;
}
.pagetop_block #pagetop a::before {
  font-family: "fontello";
  content: "\e800";
  font-size: 16px;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .pagetop_block #pagetop a {
    opacity: 1 !important;
  }
}

.breadcrumb_block {
  width: 1200px;
  margin: 10px auto 0;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .breadcrumb_block {
    width: 85%;
  }
}
@media screen and (max-width: 767px) {
  .breadcrumb_block {
    width: 85%;
  }
}
.breadcrumb_block ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
@media screen and (max-width: 767px) {
  .breadcrumb_block ul {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
}
.breadcrumb_block ul li,
.breadcrumb_block ul a {
  color: #1e1e1e;
  font-size: 13px;
}
.breadcrumb_block ul li {
  padding-right: 30px;
  position: relative;
}
.breadcrumb_block ul li::before {
  font-family: "fontello";
  content: "\e800";
  position: absolute;
  right: 10px;
  transform: rotate(90deg);
}
.breadcrumb_block ul li:last-of-type::before {
  content: "";
}
@media screen and (max-width: 767px) {
  .breadcrumb_block ul li {
    white-space: nowrap;
  }
}

#mw_wp_form_mw-wp-form-12 p {
  font-size: 16px;
}
#mw_wp_form_mw-wp-form-12 p:last-of-type {
  margin-bottom: 0;
}

.mw_wp_form_preview form p:nth-of-type(even) {
  border-bottom: 1px dotted #d0d0d0;
  padding-bottom: 20px;
}

.form_item input {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
}
.form_item textarea {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
}
.form_item .red_text {
  font-size: 14px;
}

.form_item_button {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
@media screen and (max-width: 767px) {
  .form_item_button {
    margin-top: 24px;
  }
}
.form_item_button input {
  border: none;
  background: #070EF3;
  color: #fff;
  font-size: 16px;
  padding: 10px;
  width: 140px;
  line-height: 1;
  border-radius: 5px;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .form_item_button input {
    width: 110px;
  }
}
.form_item_button input:hover {
  opacity: 0.8;
}
.form_item_button input:nth-of-type(2) {
  margin-left: 20px;
}

.home_button {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
.home_button a {
  display: block;
  border: none;
  text-decoration: none;
  background: #070EF3;
  color: #fff;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  width: 160px;
  line-height: 1;
  border-radius: 5px;
  cursor: pointer;
}
.home_button a:hover {
  opacity: 0.8;
}

a[href*="tel:"] {
  text-decoration: underline;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  a[href*="tel:"] {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
}
@media screen and (min-width: 1401px) {
  a[href*="tel:"] {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
}

@media screen and (max-width: 767px) {
  .card_item {
    margin-bottom: 40px;
  }
  .card_item:last-of-type {
    margin-bottom: 0;
  }
}
@media all and (-ms-high-contrast: none) {
  .card_item {
    padding: 0 12px;
  }
}
.card_item figure {
  margin: 0;
}
.card_item figure img {
  height: auto;
  border-radius: 0.25rem 0.25rem 0 0;
}

.card-body {
  padding: 2rem 1rem;
}

.card_title {
  font-size: 16px;
  margin-bottom: 1rem;
}

.card_text {
  margin-bottom: 1rem;
}
@media screen and (max-width: 767px) {
  .card_text {
    margin-bottom: 2rem;
  }
}

.blog_post_date {
  font-size: 13px;
  margin-bottom: 10px;
  display: block;
}

.card_button a {
  width: 80%;
  height: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  background: #1e1e1e;
}
@media screen and (max-width: 767px) {
  .card_button a {
    width: 200px;
  }
}

.bg_gray-light {
  background: #e2e2e2;
}

.bg_gray-lighter {
  background: #f7f7f7;
}

.d_flex {
  display: flex;
}

.justify_center {
  justify-content: center;
}

.justify_between {
  justify-content: space-between;
}

.justify_around {
  justify-content: space-around;
}

.align_items_center {
  align-items: center;
}

@media screen and (max-width: 767px) {
  .sm_order1 {
    order: 1;
  }
}

@media screen and (max-width: 767px) {
  .sm_order2 {
    order: 2;
    margin-top: 30px;
  }
}

.header-catalog-btn,
.header-contact-btn,
.main-btn,
.main-btn-white,
.blog-main-btn {
  width: 200px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}
.header-catalog-btn a,
.header-contact-btn a,
.main-btn a,
.main-btn-white a,
.blog-main-btn a {
  width: 200px;
  display: inline-block;
  position: relative;
  transition: all 0.3s;
}
.header-catalog-btn a:before, .header-catalog-btn a:after,
.header-contact-btn a:before,
.header-contact-btn a:after,
.main-btn a:before,
.main-btn a:after,
.main-btn-white a:before,
.main-btn-white a:after,
.blog-main-btn a:before,
.blog-main-btn a:after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
}
.header-catalog-btn a:before,
.header-contact-btn a:before,
.main-btn a:before,
.main-btn-white a:before,
.blog-main-btn a:before {
  right: 25px;
  background: #fff;
  z-index: 1;
  width: 5px;
  height: 5px;
  border-radius: 4rem;
  transform: translateY(-50%);
  transition: all 0.5s;
  z-index: 2;
}
.header-catalog-btn a:after,
.header-contact-btn a:after,
.main-btn a:after,
.main-btn-white a:after,
.blog-main-btn a:after {
  right: 0;
  background: #070EF3;
  z-index: 1;
  width: 55px;
  height: 55px;
  border-radius: 4rem;
  transform: translateY(-50%);
  transition: all 0.5s;
}
@media screen and (min-width: 1001px) {
  .header-catalog-btn a:hover,
  .header-contact-btn a:hover,
  .main-btn a:hover,
  .main-btn-white a:hover,
  .blog-main-btn a:hover {
    opacity: 1;
  }
  .header-catalog-btn a:hover::before,
  .header-contact-btn a:hover::before,
  .main-btn a:hover::before,
  .main-btn-white a:hover::before,
  .blog-main-btn a:hover::before {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    width: 10px;
    height: 10px;
    right: 20px;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    z-index: 2;
    border-radius: initial !important;
    background: none;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .header-catalog-btn a:hover:after,
  .header-contact-btn a:hover:after,
  .main-btn a:hover:after,
  .main-btn-white a:hover:after,
  .blog-main-btn a:hover:after {
    right: -10px;
    width: 65px;
    height: 65px;
    transform: translateY(-50%);
    transition: all 0.5s;
  }
}
.header-catalog-btn a span,
.header-contact-btn a span,
.main-btn a span,
.main-btn-white a span,
.blog-main-btn a span {
  position: relative;
  transition: all 0.3s;
  z-index: 3;
  color: #1e1e1e;
  font-weight: bold;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .header-catalog-btn a span,
  .header-contact-btn a span,
  .main-btn a span,
  .main-btn-white a span,
  .blog-main-btn a span {
    left: -10px;
  }
}

.header-catalog-btn a:before,
.header-contact-btn a:before {
  right: 20px;
  width: 0.25rem;
  height: 0.25rem;
}
.header-catalog-btn a:after,
.header-contact-btn a:after {
  width: 2.8rem;
  height: 2.8rem;
}
@media screen and (min-width: 1001px) {
  .header-catalog-btn a:hover,
  .header-contact-btn a:hover {
    opacity: 1;
  }
  .header-catalog-btn a:hover::before,
  .header-contact-btn a:hover::before {
    width: 8px;
    height: 8px;
    right: 18px;
  }
  .header-catalog-btn a:hover:after,
  .header-contact-btn a:hover:after {
    right: -9px;
    width: 3.5rem;
    height: 3.5rem;
    transform: translateY(-50%);
    transition: all 0.5s;
  }
}

.header-catalog-btn a:after {
  background: #1068FF;
}

.main-btn,
.main-btn-white {
  width: 165px;
  margin: 0;
}
.main-btn a,
.main-btn-white a {
  width: 165px;
}

.blog-main-btn {
  width: 175px;
  margin: 0;
}
.blog-main-btn a {
  width: 175px;
}

.main-btn-white a:before {
  background: #070EF3;
}
.main-btn-white a:after {
  background: transparent;
  border: 1.5px solid #070EF3;
}
@media screen and (min-width: 1001px) {
  .main-btn-white a:hover {
    opacity: 1;
  }
  .main-btn-white a:hover::before {
    border-top: solid 2px #070EF3;
    border-right: solid 2px #070EF3;
  }
}

.catalog-btn-white,
.contact-btn-white {
  width: 205px;
  display: inline-block;
  position: relative;
  transition: all 0.3s;
}
.catalog-btn-white:before, .catalog-btn-white:after,
.contact-btn-white:before,
.contact-btn-white:after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
}
.catalog-btn-white:before,
.contact-btn-white:before {
  right: 25px;
  background: #fff;
  z-index: 1;
  width: 5px;
  height: 5px;
  border-radius: 4rem;
  transform: translateY(-50%);
  transition: all 0.5s;
  z-index: 2;
}
.catalog-btn-white:after,
.contact-btn-white:after {
  right: 0;
  z-index: 1;
  width: 55px;
  height: 55px;
  border-radius: 4rem;
  transform: translateY(-50%);
  transition: all 0.5s;
  background: transparent;
  border: 1.5px solid #fff;
}
.catalog-btn-white span,
.contact-btn-white span {
  color: #fff;
}

.catalog-btn-white {
  width: 180px;
}

.btn01 {
  margin-top: 40px;
}
.btn01 a {
  color: #fff;
  width: 300px;
  height: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: #1e1e1e;
}
@media screen and (max-width: 767px) {
  .btn01 a {
    width: 80%;
  }
}

.pagination {
  margin-top: 40px;
  justify-content: center;
}
.pagination a {
  color: #1e1e1e;
}
.pagination .page-numbers {
  margin: 0 10px;
}
.pagination .current {
  border-bottom: 1px solid #1e1e1e;
}

.loading {
  background: linear-gradient(#0308C1, #65ACFE);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9000;
}

.loading div {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 9999;
  width: 220px;
}
@media screen and (max-width: 767px) {
  .loading div {
    width: 180px;
  }
}

#elevator_nav {
  position: fixed;
  right: 0;
  top: 45%;
  padding: 30px 10px 30px 0;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  #elevator_nav {
    background: none;
    right: 1%;
  }
}
@media screen and (max-width: 767px) {
  #elevator_nav {
    display: none;
  }
}
#elevator_nav ul {
  padding: 0;
  list-style: none;
  margin: 0;
  position: relative;
}
#elevator_nav ul::after {
  content: "";
  position: absolute;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  height: 180px;
  top: 10px;
  left: 11px;
  z-index: 0;
}
#elevator_nav ul li {
  margin-bottom: 25px;
}
#elevator_nav ul li:last-of-type {
  margin-bottom: 0;
  font-size: 13px;
}
#elevator_nav ul li a {
  text-decoration: none;
  display: flex;
  align-items: center;
}
#elevator_nav ul li a::before {
  font-size: 13px;
  font-family: "fontello";
  content: "\e807";
  color: rgba(0, 0, 0, 0.125);
  width: 24px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}
#elevator_nav ul li a span {
  font-size: 13px;
}

.current::before {
  color: #1068FF !important;
  font-size: 16px !important;
}

.fadein {
  opacity: 0;
  transform: translate(0, 100px);
  transition: all 1s;
}

.fadein.active {
  opacity: 1;
  transform: translate(0, 0);
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb45 {
  margin-bottom: 45px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb55 {
  margin-bottom: 55px;
}

.mb60 {
  margin-bottom: 60px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pt25 {
  padding-top: 25px;
}

.pt30 {
  padding-top: 30px;
}

.pt35 {
  padding-top: 35px;
}

.pt40 {
  padding-top: 40px;
}

.pt45 {
  padding-top: 45px;
}

.pt50 {
  padding-top: 50px;
}

.pt55 {
  padding-top: 55px;
}

.pt60 {
  padding-top: 60px;
}

* {
  color: #1e1e1e;
  font-weight: 400;
}

p,
a,
li,
table {
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  p,
  a,
  li,
  table {
    font-size: 16px;
  }
}

p {
  text-align: justify;
  text-justify: inter-ideograph;
  line-height: 2;
  margin: 0;
}
@media screen and (max-width: 767px) {
  p {
    line-height: 1.5;
  }
}

h2 {
  font-size: 32px;
  margin: 0 0 40px;
  color: #1e1e1e;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  h2 {
    font-size: 28px;
  }
}

h3 {
  margin: 0 0 20px;
  font-size: 23px;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  h4 {
    font-size: 18px;
  }
}

.text_link {
  font-weight: bold;
  color: #1068FF;
}

.fw {
  font-weight: bold;
}

.red_text {
  color: #E63A05;
}

.yellow_text {
  color: #f3cb2b;
}

.pink_text {
  color: #ef75c4;
}

.under_line {
  font-weight: bold;
  border-bottom: 1px dashed #1e1e1e;
}

.note,
.quote {
  margin-top: 10px;
  font-size: 13px;
}
.note a,
.quote a {
  font-size: 13px;
}

.note {
  position: relative;
  padding-left: 16px;
}
.note::before {
  content: "※";
  position: absolute;
  left: 0;
  top: 0;
}

.align_c {
  text-align: center;
}

.align_l {
  text-align: left;
}

.align_r {
  text-align: right;
}

@media screen and (max-width: 767px) {
  .sm_none {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .md_none {
    display: none;
  }
}

@media screen and (min-width: 1401px) {
  .lg_none {
    display: none;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.mv-row {
  --bs-gutter-x: 0rem;
}

.mv-row > * {
  padding: 0;
  margin: 0;
}

.main_visual {
  position: relative;
}
.main_visual .main_visual_inner {
  position: relative;
}
.main_visual .main_visual_inner .slick-slider {
  margin: 0;
  padding: 0;
}
.main_visual .main_visual_inner .slick-slider .slick-slide img {
  width: 60%;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  mix-blend-mode: multiply;
  margin-left: 40%;
}
@media screen and (max-width: 767px) {
  .main_visual .main_visual_inner .slick-slider .slick-slide img {
    width: 100% !important;
    margin-left: 0;
    height: 50vh;
  }
}
.main_visual .main_visual_inner .slick-slider .slick-prev {
  left: 25px;
  z-index: 9;
  width: auto;
  height: auto;
}
.main_visual .main_visual_inner .slick-slider .slick-prev::before {
  font-size: 40px;
  font-family: "fontello";
  content: "\e804";
  color: #fff;
}
.main_visual .main_visual_inner .slick-slider .slick-next {
  right: 25px;
  z-index: 9;
  width: auto;
  height: auto;
}
.main_visual .main_visual_inner .slick-slider .slick-next::before {
  font-size: 40px;
  font-family: "fontello";
  content: "\e805";
  color: #fff;
}
.main_visual .main_visual_inner .logo_sp_top {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 15%;
}
.main_visual .main_visual_inner .logo_sp_top img {
  width: 170px;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .main_visual .main_visual_inner .logo_sp_top {
    display: none;
  }
}
@media screen and (min-width: 1401px) {
  .main_visual .main_visual_inner .logo_sp_top {
    display: none;
  }
}
.main_visual .main_visual_inner .catch-box {
  width: 40%;
  background: linear-gradient(#0308C1, #65ACFE);
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .main_visual .main_visual_inner .catch-box {
    width: 100%;
    position: relative;
    display: block;
    height: 300px;
    margin-top: -20px;
  }
}
.main_visual .main_visual_inner .catch-box .catch_copy {
  position: absolute;
  width: 69%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .main_visual .main_visual_inner .catch-box .catch_copy {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
.main_visual .main_visual_inner .catch-box .catch_copy .catch-logo {
  width: 120px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .main_visual .main_visual_inner .catch-box .catch_copy .catch-logo {
    width: 80px;
  }
}
@media screen and (max-width: 767px) {
  .main_visual .main_visual_inner .catch-box .catch_copy .catch-logo {
    display: none;
  }
}
.main_visual .main_visual_inner .catch-box .catch_copy h1 {
  font-size: 38px;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
}
@media screen and (min-width: 1401px) and (max-width: 1486px) {
  .main_visual .main_visual_inner .catch-box .catch_copy h1 {
    font-size: 2.5vw !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1400px) {
  .main_visual .main_visual_inner .catch-box .catch_copy h1 {
    font-size: 2.9vw;
  }
}
@media screen and (max-width: 767px) {
  .main_visual .main_visual_inner .catch-box .catch_copy h1 {
    font-size: 25px;
  }
}
.main_visual .main_visual_inner .catch-box .catch_copy p {
  font-size: 16px;
  color: #fff;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .main_visual .main_visual_inner .catch-box .catch_copy p {
    font-size: 14px;
  }
}
.main_visual .circle {
  position: absolute;
  right: 3%;
  bottom: 5%;
}
@media screen and (max-width: 767px) {
  .main_visual .circle {
    display: none;
  }
}
.main_visual .circle::before {
  content: "↓";
  font-size: 45px;
  color: #1068FF;
  font-weight: 500;
  position: absolute;
  top: 25%;
  left: 35%;
}
.main_visual .circle img {
  width: 150px;
  animation: rotate-anime 10s linear infinite;
}
@media screen and (max-width: 767px) {
  .main_visual .circle img {
    width: 100px;
  }
}
@keyframes rotate-anime {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.top-products .row {
  justify-content: space-between;
}
.top-products .row .top-title {
  margin-bottom: 20px;
}
.top-products .row .main-btn {
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .top-products .row .main-btn {
    margin-bottom: 50px;
  }
}

.top-products-item {
  padding-bottom: 0px;
}
@media screen and (max-width: 767px) {
  .top-products-item {
    padding-bottom: 0;
  }
}
.top-products-item .row {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 80px;
  margin-top: 80px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
@media screen and (max-width: 767px) {
  .top-products-item .row {
    display: block;
    border: none;
    margin: 0;
    padding: 0;
  }
}
.top-products-item .row:first-child {
  margin-top: 0;
}
.top-products-item .row:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.top-products-item .row .col-md-5 {
  width: 48%;
}
@media screen and (max-width: 767px) {
  .top-products-item .row .col-md-5 {
    width: 100%;
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
}
.top-products-item .row .col-md-5 .item-title {
  margin-bottom: 20px;
}
.top-products-item .row .col-md-5 .text {
  margin-top: 20px;
  margin-bottom: 20px;
}
.top-products-item .row .col-md-5 .main-btn-white {
  margin-left: auto;
}
.top-products-item .row .col-md-5 img {
  height: 350px;
  object-fit: cover;
  object-position: center;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .top-products-item .row .col-md-5 img {
    height: 250px;
  }
}
@media screen and (max-width: 767px) {
  .top-products-item .row .col-md-5 img {
    height: 250px;
  }
}
@media screen and (max-width: 767px) {
  .top-products-item .item-row-first {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 767px) {
  .top-products-item .item-row-first .col-md-5 {
    border-bottom: none;
    padding: 0;
    margin: 0;
  }
}
@media screen and (max-width: 767px) {
  .top-products-item .item-row-first .col-md-5 .main-btn-white {
    margin-bottom: 40px;
  }
}
.top-products-item .item-row-first .text {
  margin-top: 40px;
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  .top-products-item .last-item {
    border-bottom: none !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}

.flow-text {
  margin-bottom: 40px;
  align-items: center;
  display: flex;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  margin-top: 0px;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .flow-text {
    margin: 0;
  }
}
.flow-text ul {
  animation: flowing 150s linear infinite;
  transform: translateX(100%);
  margin: 0;
  padding: 0;
}
.flow-text ul li {
  font-size: 250px;
  font-weight: 700;
  color: rgb(212, 228, 255);
  display: inline-block;
  padding-right: 10px;
}
@media screen and (max-width: 767px) {
  .flow-text ul li {
    font-size: 150px;
  }
}

@keyframes flowing {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.top-cases {
  padding-top: 0px;
}
@media screen and (max-width: 767px) {
  .top-cases {
    padding-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .top-cases .blog-main-btn {
    margin-left: auto;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 767px) {
  .top-cases .top-title {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .top-cases .card_item {
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  .top-cases .card_item:last-child {
    border: none;
    padding-bottom: 0;
  }
}
.top-cases .no-blog {
  font-size: 16px;
  padding-left: 10px;
}
.top-cases .card {
  border: none;
}
.top-cases .card .card-body {
  padding: 0;
  margin-bottom: 15px;
}
.top-cases .card .card-body .blog_post_date {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 14px;
  color: #818181;
}
.top-cases .card .card-body .card_title {
  margin-top: 10px;
  margin-bottom: 10px;
}
.top-cases .category {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #1068FF;
  color: #1068FF;
  font-size: 14px;
  font-weight: 700;
}

.top-company .row {
  --bs-gutter-x: 0rem;
  --bs-gutter-y: 0;
  height: 700px;
}
@media screen and (max-width: 767px) {
  .top-company .row {
    height: auto;
  }
}
.top-company .row > * {
  padding: 0;
}
@media screen and (max-width: 767px) {
  .top-company .row .bg-gray {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.top-company .row .col-md-6 .img img {
  height: 700px;
  object-fit: cover;
  object-position: center;
}
@media screen and (max-width: 767px) {
  .top-company .row .col-md-6 .img img {
    height: 300px;
  }
}
.top-company .row .company-text {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);
  top: 50%;
  position: relative;
}
.top-company .row .company-text .top-title {
  margin-bottom: 0;
}
.top-company .row .company-text .text {
  margin-top: 40px;
  margin-bottom: 40px;
}
.top-company .row .company-text .main-btn {
  margin-left: auto;
}

.top-gallery ul {
  padding-left: 0;
}
@keyframes infinity-scroll-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
.top-gallery .gallery-wrap {
  display: flex;
  overflow: hidden;
}
.top-gallery .gallery-wrap .gallery-list {
  display: flex;
  list-style: none;
  margin-bottom: 0;
}
.top-gallery .gallery-wrap .gallery-list .gallery-item {
  padding-left: 20px;
}
.top-gallery .gallery-wrap .gallery-list .gallery-item img {
  width: 420px;
  height: 420px;
  object-fit: cover;
  object-position: center;
}
@media screen and (max-width: 767px) {
  .top-gallery .gallery-wrap .gallery-list .gallery-item img {
    height: 200px;
    width: 200px;
  }
}
.top-gallery .gallery-wrap .gallery-list--left {
  animation: infinity-scroll-left 30s infinite linear 0.5s both;
}

.top-news {
  padding-top: 0;
}
@media screen and (max-width: 767px) {
  .top-news {
    padding-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .top-news .top-title {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .top-news .blog-main-btn {
    margin-left: auto;
    margin-bottom: 50px;
  }
}
.top-news .news_list {
  display: block;
  width: 100%;
  border: solid 1px rgba(0, 0, 0, 0.125);
  border-radius: 20px;
  position: relative;
  margin-bottom: 20px;
  transition: all 0.5s;
}
.top-news .news_list:last-child {
  margin-bottom: 0;
}
.top-news .news_list:before, .top-news .news_list:after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
}
@media screen and (max-width: 767px) {
  .top-news .news_list:before, .top-news .news_list:after {
    display: none;
  }
}
.top-news .news_list:before {
  right: 65px;
  background: #1068FF;
  z-index: 1;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: all 0.5s;
  z-index: 2;
}
.top-news .news_list:after {
  border: 1px solid #1068FF;
  right: 40px;
  z-index: 1;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: all 0.5s;
}
@media screen and (min-width: 1001px) {
  .top-news .news_list:hover {
    opacity: 1;
    border: solid 1px #1068FF;
  }
  .top-news .news_list:hover::before {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    width: 10px;
    height: 10px;
    right: 70px;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    z-index: 2;
    border-radius: initial !important;
    background: none;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .top-news .news_list:hover:after {
    border: none;
    background: #1068FF;
    width: 65px;
    height: 65px;
    transform: translateY(-50%);
    transition: all 0.5s;
  }
}
.top-news .news_list dl {
  padding: 25px 40px;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .top-news .news_list dl {
    padding: 15px 20px;
  }
}
.top-news .news_list dl dt time {
  font-size: 14px;
  font-weight: 700;
  color: #1068FF;
}
.top-news .news_list dl .news-title {
  font-size: 16px;
  font-weight: 500;
}

.top-contact-catalog .row {
  --bs-gutter-x: 0rem;
  --bs-gutter-y: 0;
  height: 400px;
}
.top-contact-catalog .row > * {
  padding: 0;
}
.top-contact-catalog .row .top-title-white {
  font-size: 60px;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .top-contact-catalog .row .top-title-white {
    font-size: 6vw;
  }
}
@media screen and (max-width: 767px) {
  .top-contact-catalog .row .top-title-white {
    font-size: 40px;
    margin-bottom: 20px;
  }
}
.top-contact-catalog .row .japanese-white {
  font-size: 12px;
}
.top-contact-catalog .row .col-contact {
  background-color: #070EF3;
  border-right: 1px solid #fff;
  position: relative;
  transition: all 1s;
}
@media screen and (min-width: 1001px) {
  .top-contact-catalog .row .col-contact:hover {
    background-image: url(assets/images/top/top-contact.jpg);
    background-position: center;
    background-size: cover;
    transition: all 1s;
    position: relative;
  }
  .top-contact-catalog .row .col-contact:hover::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
}
.top-contact-catalog .row .col-catalog {
  position: relative;
  background-color: #1068FF;
  transition: all 1s;
}
@media screen and (min-width: 1001px) {
  .top-contact-catalog .row .col-catalog:hover {
    background-image: url(assets/images/top/top-catalog.jpg);
    background-position: center;
    background-size: cover;
    transition: all 1s;
    position: relative;
  }
  .top-contact-catalog .row .col-catalog:hover::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
}
.top-contact-catalog .row .col-contact-inner,
.top-contact-catalog .row .col-catalog-inner {
  position: relative;
  display: block;
  height: 100%;
}
@media screen and (min-width: 1001px) {
  .top-contact-catalog .row .col-contact-inner:hover,
  .top-contact-catalog .row .col-catalog-inner:hover {
    opacity: 1;
  }
  .top-contact-catalog .row .col-contact-inner:hover .contact-btn-white,
  .top-contact-catalog .row .col-contact-inner:hover .catalog-btn-white,
  .top-contact-catalog .row .col-catalog-inner:hover .contact-btn-white,
  .top-contact-catalog .row .col-catalog-inner:hover .catalog-btn-white {
    position: relative;
    margin-top: 40px;
    margin-left: auto;
    display: inherit;
  }
}
@media screen and (min-width: 1001px) and (max-width: 767px) {
  .top-contact-catalog .row .col-contact-inner:hover .contact-btn-white,
  .top-contact-catalog .row .col-contact-inner:hover .catalog-btn-white,
  .top-contact-catalog .row .col-catalog-inner:hover .contact-btn-white,
  .top-contact-catalog .row .col-catalog-inner:hover .catalog-btn-white {
    margin-top: 0 !important;
  }
}
@media screen and (min-width: 1001px) {
  .top-contact-catalog .row .col-contact-inner:hover .contact-btn-white::before,
  .top-contact-catalog .row .col-contact-inner:hover .catalog-btn-white::before,
  .top-contact-catalog .row .col-catalog-inner:hover .contact-btn-white::before,
  .top-contact-catalog .row .col-catalog-inner:hover .catalog-btn-white::before {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    width: 10px;
    height: 10px;
    right: 20px;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    z-index: 2;
    border-radius: initial !important;
    background: none;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .top-contact-catalog .row .col-contact-inner:hover .contact-btn-white:after,
  .top-contact-catalog .row .col-contact-inner:hover .catalog-btn-white:after,
  .top-contact-catalog .row .col-catalog-inner:hover .contact-btn-white:after,
  .top-contact-catalog .row .col-catalog-inner:hover .catalog-btn-white:after {
    right: -10px;
    width: 65px;
    height: 65px;
    transform: translateY(-50%);
    transition: all 0.5s;
    background: #fff;
  }
  .top-contact-catalog .row .col-contact-inner:hover .contact-btn-white::before,
  .top-contact-catalog .row .col-catalog-inner:hover .contact-btn-white::before {
    border-top: solid 2px #070EF3;
    border-right: solid 2px #070EF3;
  }
  .top-contact-catalog .row .col-contact-inner:hover .catalog-btn-white::before,
  .top-contact-catalog .row .col-catalog-inner:hover .catalog-btn-white::before {
    border-top: solid 2px #1068FF;
    border-right: solid 2px #1068FF;
  }
}
.top-contact-catalog .row .col-contact-inner .box,
.top-contact-catalog .row .col-catalog-inner .box {
  position: relative;
  height: auto;
  transform: translateY(-50%);
  top: 50%;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.top-contact-catalog .row .contact-btn-white,
.top-contact-catalog .row .catalog-btn-white {
  position: relative;
  margin-top: 40px;
  margin-left: auto;
  display: inherit;
}
@media screen and (max-width: 767px) {
  .top-contact-catalog .row .contact-btn-white,
  .top-contact-catalog .row .catalog-btn-white {
    font-size: 14px;
    margin-top: 0 !important;
  }
}

.news_block {
  width: 100%;
  background: #f7f7f7;
}
.news_block .news_block_inner {
  width: 980px;
  margin: 0 auto;
  padding: 40px;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .news_block .news_block_inner {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .news_block .news_block_inner {
    width: 90%;
    flex-wrap: wrap;
  }
}
.news_block .news_block_inner .news_heading {
  width: 20%;
}
@media screen and (max-width: 767px) {
  .news_block .news_block_inner .news_heading {
    width: 100%;
  }
}
.news_block .news_block_inner .news_heading h3 {
  text-align: center;
  color: #1e1e1e;
  font-size: 24px;
  margin: 0;
}
.news_block .news_block_inner .news_heading .archive_btn {
  margin: 15px 0 0;
  text-align: center;
}
.news_block .news_block_inner .news_heading .archive_btn a {
  text-decoration: none;
  color: #fff;
  background: #070EF3;
  padding: 2px 15px 3px;
  border-radius: 20px;
  font-size: 12px;
}
.news_block .news_block_inner .news_list {
  padding: 0 0 0 30px;
  width: 90%;
}
@media screen and (max-width: 767px) {
  .news_block .news_block_inner .news_list {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
.news_block .news_block_inner .news_list dl {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin: 0;
  padding: 10px 0;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .news_block .news_block_inner .news_list dl {
    padding: 20px 0;
    flex-wrap: wrap;
  }
}
.news_block .news_block_inner .news_list dl:last-of-type {
  border-bottom: 0;
}
.news_block .news_block_inner .news_list dl dt {
  font-size: 12px;
  color: #070EF3;
  margin-right: 20px;
}
@media screen and (max-width: 767px) {
  .news_block .news_block_inner .news_list dl dt {
    width: 100%;
  }
}
.news_block .news_block_inner .news_list dl dt time {
  color: #070EF3;
  font-weight: bold;
}
.news_block .news_block_inner .news_list dl dd {
  margin: 0;
}
@media screen and (max-width: 767px) {
  .news_block .news_block_inner .news_list dl dd {
    width: 100%;
  }
}
.news_block .news_block_inner .news_list dl dd a {
  color: #1e1e1e;
  font-size: 14px;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .news_block .news_block_inner .news_list dl dd a {
    font-size: 16px;
  }
}
@media screen and (min-width: 1001px) {
  .news_block .news_block_inner .news_list dl dd a:hover {
    color: #1e1e1e;
    opacity: 0.8;
  }
}
.news_block .news_block_inner .archive_btn_sp {
  margin: 10px auto;
}
@media screen and (max-width: 767px) {
  .news_block .news_block_inner .archive_btn_sp {
    width: 250px;
    margin: 30px auto 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .news_block .news_block_inner .archive_btn_sp {
    display: none;
  }
}
@media screen and (min-width: 1401px) {
  .news_block .news_block_inner .archive_btn_sp {
    display: none;
  }
}
.news_block .news_block_inner .archive_btn_sp a {
  text-decoration: none;
  color: #fff;
  background: #070EF3;
  padding: 10px 0;
  border-radius: 20px;
  font-size: 16px;
  display: block;
  text-align: center;
}

.carousel_slider .slick-slider {
  cursor: pointer;
}
.carousel_slider .slick-slider .slick-track {
  display: flex;
  justify-content: space-between;
}
.carousel_slider .slick-slider .slick-slide {
  margin-right: 10px;
}
.carousel_slider .slick-slider .slick-slide img {
  border-radius: 0.25rem;
}
.carousel_slider .slick-slider .slick-list {
  margin-right: -10px;
}
.carousel_slider .slick-slider .slick-prev {
  left: 25px;
  top: 47%;
  z-index: 9;
}
.carousel_slider .slick-slider .slick-prev::before {
  font-size: 40px;
  font-family: "fontello";
  content: "\e804";
  color: #fff;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
}
@media screen and (max-width: 767px) {
  .carousel_slider .slick-slider .slick-prev::before {
    font-size: 30px;
  }
}
@media screen and (max-width: 767px) {
  .carousel_slider .slick-slider .slick-prev {
    left: 10px;
  }
}
.carousel_slider .slick-slider .slick-next {
  right: 25px;
  top: 47%;
  z-index: 9;
}
.carousel_slider .slick-slider .slick-next::before {
  font-size: 40px;
  font-family: "fontello";
  content: "\e805";
  color: #fff;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
}
@media screen and (max-width: 767px) {
  .carousel_slider .slick-slider .slick-next::before {
    font-size: 30px;
  }
}
@media screen and (max-width: 767px) {
  .carousel_slider .slick-slider .slick-next {
    right: 10px;
  }
}

.galleryList {
  width: 100%;
}

.modalBg {
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.modalContent {
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 101;
  cursor: pointer;
}

.modalImg {
  position: relative;
  width: 800px;
}

.modalImg .closeBtn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 103;
  border: 0;
  background: #070EF3;
  border-radius: 0;
  padding: 10px;
  cursor: pointer;
  outline: none;
}
.modalImg .closeBtn i {
  color: #fff;
  font-size: 20px;
  line-height: 1;
}

#top_content02 .visual_title {
  background-image: url(assets/images/top/visual_title_sample1.jpg);
  background-color: #ccc;
  background-blend-mode: multiply;
}
#top_content02 .visual_title h2 {
  color: #fff;
}
#top_content02 .visual_title p {
  color: #fff;
}

.main_visual_subpage {
  width: 100%;
  height: 300px;
  background-image: url(assets/images/common/main_visual_sample.jpg);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .main_visual_subpage {
    margin-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .main_visual_subpage {
    height: 250px;
    margin-top: 0;
  }
}
@media all and (-ms-high-contrast: none) {
  .main_visual_subpage .page_name {
    width: 100%;
    text-align: center;
  }
}
.main_visual_subpage .page_name h1 {
  font-weight: bold;
  font-size: 30px;
}
@media screen and (max-width: 767px) {
  .main_visual_subpage .page_name h1 {
    font-size: 25px;
  }
}

.mv_sample {
  background-image: url(assets/images/subpage/main_visual_sample2.jpg);
}

.under_content {
  width: 100%;
  padding-top: 80px;
}
@media screen and (max-width: 767px) {
  .under_content {
    width: 100%;
  }
}
.under_content .content_inner {
  width: 1100px;
  padding: 0 50px;
  margin: 0 auto;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .under_content .content_inner {
    width: 100%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 767px) {
  .under_content .content_inner {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }
}
.under_content .content_inner h2 {
  font-size: 28px;
  margin: 0 0 40px;
  padding-bottom: 10px;
  color: #fff;
  border-bottom: 7px double #fff;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .under_content .content_inner h2 {
    font-size: 24px;
  }
}
.under_content .content_inner h3 {
  margin: 0 0 20px;
  font-size: 23px;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .under_content .content_inner h3 {
    font-size: 20px;
  }
}
.under_content .content_inner h4 {
  font-size: 21px;
  font-weight: bold;
  border-bottom: 1px solid #fff;
  padding: 0 0 10px 1em;
  margin-bottom: 10px;
  position: relative;
}
.under_content .content_inner h4::before {
  font-size: 13px;
  font-family: "fontello";
  content: "\e807";
  color: #fff;
  margin-right: 10px;
  position: absolute;
  top: 8px;
  left: 0;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .under_content .content_inner h4 {
    font-size: 17px;
  }
  .under_content .content_inner h4::before {
    top: 4px;
  }
}
@media screen and (max-width: 767px) {
  .under_content .content_inner h4 {
    font-size: 17px;
  }
  .under_content .content_inner h4::before {
    top: 4px;
  }
}

.gallerypage_block {
  background: #3f3f3f;
  border-radius: 20px;
  padding: 70px 30px 60px;
  margin-bottom: 120px;
}
@media screen and (max-width: 767px) {
  .gallerypage_block {
    padding: 40px 20px;
    margin-bottom: 70px;
  }
}

.gallerypage_inner {
  display: flex;
  flex-wrap: wrap;
}
.gallerypage_inner:last-of-type {
  margin: 0;
}
.gallerypage_inner .gallerypage_inner_item {
  margin-right: 20px;
  margin-bottom: 20px;
  width: calc(33.3333333333% - 13.3333333333px);
}
.gallerypage_inner .gallerypage_inner_item:nth-child(3n) {
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  .gallerypage_inner .gallerypage_inner_item {
    margin-right: 10px;
    margin-bottom: 10px;
    width: calc(33.3333333333% - 6.6666666667px);
  }
}
.gallerypage_inner .gallerypage_inner_item img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 10px;
  cursor: pointer;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .gallerypage_inner .gallerypage_inner_item img {
    height: auto;
  }
}
@media screen and (max-width: 767px) {
  .gallerypage_inner .gallerypage_inner_item img {
    border-radius: 5px;
  }
}
.gallerypage_inner .gallerypage_inner_item p {
  font-size: 13px;
}
@media screen and (max-width: 767px) {
  .gallerypage_inner .gallerypage_inner_item p {
    font-size: 16px;
  }
}

.password_block {
  margin-bottom: 120px;
}
@media screen and (max-width: 767px) {
  .password_block {
    margin-bottom: 70px;
  }
}
.password_block .content_inner {
  text-align: center;
}
.password_block p {
  margin-bottom: 20px;
}
.password_block input[type=password] {
  color: #070EF3;
  width: 50%;
  height: 35px;
  display: block;
  margin: 0 auto;
}
.password_block input[type=password]::placeholder {
  color: #575757;
}
@media screen and (max-width: 767px) {
  .password_block input[type=password] {
    width: 90%;
    font-size: 16px;
  }
}
.password_block input[type=submit] {
  background: #E63A05;
  color: #fff;
  margin: 0 auto;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 30px;
  width: 250px;
  height: 35px;
}

.memberpage_block {
  background: #3f3f3f;
  border-radius: 20px;
  padding: 70px 50px 60px;
  margin-bottom: 120px;
}
@media screen and (max-width: 767px) {
  .memberpage_block {
    padding: 40px 20px;
    margin-bottom: 70px;
  }
}
.memberpage_block .video_block {
  margin-bottom: 70px;
}
@media screen and (max-width: 767px) {
  .memberpage_block .video_block {
    margin-bottom: 40px;
  }
}
.memberpage_block .video_block:last-of-type {
  margin-bottom: 0;
}
.memberpage_block .video_block h3 {
  font-size: 23px;
  font-weight: bold;
  border-bottom: 1px solid #fff;
  padding: 0 0 10px 1em;
  margin-bottom: 10px;
  position: relative;
}
.memberpage_block .video_block h3::before {
  font-size: 13px;
  font-family: "fontello";
  content: "\e807";
  color: #fff;
  margin-right: 10px;
  position: absolute;
  top: 8px;
  left: 0;
}
@media screen and (max-width: 767px) {
  .memberpage_block .video_block h3 {
    font-size: 20px;
  }
}
.memberpage_block .video_block p {
  margin-bottom: 20px;
}
.memberpage_block .video_block p:last-of-type {
  margin-bottom: 0;
}
.memberpage_block .video_block video {
  width: 100%;
  height: auto;
}

.privacy_block {
  background: #3f3f3f;
  border-radius: 20px;
  padding: 70px 50px 60px;
  margin: 20px 0 120px;
}
@media screen and (max-width: 767px) {
  .privacy_block {
    padding: 40px 20px;
    margin: 20px 0 70px;
  }
}
.privacy_block p {
  margin-bottom: 20px;
}
.privacy_block h4 before {
  content: none;
}
.privacy_block section:last-of-type p:last-of-type {
  margin-bottom: 0;
}

.form_block {
  margin: 0 auto;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .form_block {
    padding: 0 20px;
  }
}
.form_block .form_row {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.125);
}
.form_block .form_row span {
  font-size: 13px;
}
@media screen and (max-width: 767px) {
  .form_block .form_row span {
    font-size: 16px;
  }
}
.form_block .form_row:last-child {
  margin-bottom: 0;
}
.form_block .form_btn_wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media screen and (max-width: 767px) {
  .form_block .form_btn_wrap .form_btn + .form_btn {
    margin-left: 20px;
  }
}
.form_block .form_btn {
  display: block;
  width: 300px;
  color: #fff;
  padding: 10px 0;
  border: none;
  border-radius: 50px;
  text-align: center;
  background: #070EF3;
  margin: 50px auto 0;
  text-decoration: none;
  cursor: pointer;
}
.form_block .form_btn:focus, .form_block .form_btn:hover {
  opacity: 0.8;
}
@media screen and (max-width: 767px) {
  .form_block .form_btn {
    font-size: 16px;
    width: 250px;
    margin: 30px auto 0;
  }
}
.form_block .form_btn[value=back] {
  background-color: #fff;
  border: 1px solid #070EF3;
  color: #070EF3;
}
.form_block label {
  display: block;
  margin-bottom: 10px;
  position: static;
}
.form_block input[type=text],
.form_block input[type=email],
.form_block select,
.form_block textarea {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 3px;
  padding: 10px;
}
@media screen and (max-width: 767px) {
  .form_block input[type=text],
  .form_block input[type=email],
  .form_block select,
  .form_block textarea {
    width: 100%;
    font-size: 16px;
  }
}
.form_block select {
  padding-right: 50px;
}
.form_block .mwform-radio-field {
  margin-bottom: 13px;
}
.form_block .required {
  background-color: #c87523;
  border-radius: 3px;
  color: #fff;
  font-size: 10px;
  margin-left: 10px;
  padding: 2px 10px;
  vertical-align: middle;
  word-break: keep-all;
}
.form_block .input_long {
  width: 100%;
}

.mw_wp_form_input .input_none {
  display: none;
  speak: none;
}

.mw_wp_form_preview .preview_none {
  display: none;
  speak: none;
}
.mw_wp_form_preview .input_none {
  display: block;
  speak: normal;
}

.mwform-tel-field input[type=text] {
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .mwform-tel-field input[type=text] {
    width: 30% !important;
  }
}

/* fieldset {
	margin-bottom: 100px !important;
	.div_text {
		width: 100% !important;
		margin-bottom: 30px !important;
	}
	.button_div {
		width: 100% !important;
		text-align: center !important;
		margin-top: 30px;
		.buttons {
			color: $white;
			width: 200px;
			background: $red;
			border: none;
			height: 40px;
			border-radius: 20px;
			cursor: pointer;
			&:hover {
				opacity: .8;
			}
		}
	}
	.link-text {
		width: 100% !important;
		text-align: center !important;
	}
}
#wpmem_restricted_msg {
	width: 900px;
	margin: 70px auto 0;
	text-align: center;
	display: flex;
	justify-content: center;
	@include mq(sm) {
		width: 80% !important;
	}
}
#wpmem_login_form {
	width: 900px;
	margin: 0 auto !important;
	@include mq(sm) {
		width: 80% !important;
	}
}
#wpmem_login,
#wpmem_reg {
	legend {
		margin-bottom: 30px !important;
		text-align: center;
	}
}
.memberpage_block {
	fieldset {
		margin: 0 !important;
	}
}
.wpmem_msg {
	width: 100% !important;
	margin-bottom: 30px !important;
	border: none !important;
	background: none !important;
	padding: 0 !important;
	p {
		color: $white !important;
	}
} */
.page-privacy .privacy_text_block {
  margin-bottom: 40px;
}
.page-privacy .privacy_text_block h2 {
  margin-bottom: 5px;
  font-size: 20px;
}
.page-privacy .privacy_text_block p a {
  font-weight: bold;
  color: #070EF3;
}
.page-privacy .privacy_text_block .btn01 {
  margin-top: 20px;
}

.sub-contact .content_inner {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .sub-contact .content_inner {
    width: 80%;
  }
}
@media screen and (max-width: 767px) {
  .sub-contact .content_inner {
    width: 85%;
  }
}
.sub-contact p {
  color: #070EF3;
}
.sub-contact .note {
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .sub-contact .note {
    font-size: 14px;
  }
}
.sub-contact .fz-16 {
  font-size: 16px;
}
.sub-contact .form_block .required {
  background-color: #1e1e1e;
}
.sub-contact ::placeholder {
  color: #070EF3;
}
.sub-contact .content_inner .inner_section_block {
  margin-top: 0;
}
@media screen and (max-width: 767px) {
  .sub-contact .mwform-tel-field input[type=text] {
    width: 25% !important;
  }
}
.sub-contact .form_row_last {
  border: none;
}
@media screen and (max-width: 767px) {
  .sub-contact .form_row_last {
    margin-bottom: 0;
  }
}
.sub-contact .form_block .form_btn {
  margin: 0;
}
.sub-contact .title_block {
  bottom: 60px;
}
.sub-contact .title_block h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 25px;
}
.sub-contact label {
  color: #070EF3;
  font-size: 16px;
}
.sub-contact .align_c {
  font-size: 16px;
}
.sub-contact .confirm_text p {
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .sub-contact .confirm_text p {
    font-size: 14px !important;
  }
}
.sub-contact .home_button a {
  display: block;
  width: 300px;
  color: #fff;
  padding: 15px 0;
  border: none;
  border-radius: 50px;
  text-align: center;
  background: #1e1e1e;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .sub-contact .home_button a {
    width: 250px;
  }
}
.sub-contact .mwform-checkbox-field label,
.sub-contact .mwform-radio-field label {
  display: inline-block;
}
.sub-contact .mwform-checkbox-field-text {
  font-size: 16px !important;
  margin-right: 10px;
}
.sub-contact select {
  font-size: 16px;
}
.sub-contact input[type=text],
.sub-contact input[type=email],
.sub-contact .form_row,
.sub-contact select,
.sub-contact textarea.input_long,
.sub-contact .mw_wp_form input[type=file],
.sub-contact option {
  color: #070EF3;
}
.sub-contact .form_btn_wrap {
  margin-top: 40px;
}
.sub-contact .mwform-radio-field-text,
.sub-contact span.mwform-zip-field {
  font-size: 16px !important;
  color: #070EF3;
}

article {
  position: relative;
}
@media screen and (max-width: 767px) {
  article {
    padding: 70px 0;
  }
}
.archive article {
  background-color: #f7f7f7;
}

.pg_wrapper {
  max-width: 1000px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .pg_wrapper {
    padding: 0 5%;
  }
}
.pg_title {
  font-size: 30px;
  margin: 0 0 50px;
  color: #070EF3;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .pg_title {
    font-size: 24px;
  }
}
.pg_contents {
  background-color: #fff;
  padding: 50px;
  max-width: 1000px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .pg_contents {
    padding: 50px 20px;
  }
}

.posts_item_list {
  display: flex;
  flex-wrap: wrap;
}
.posts_item_list .posts_item {
  margin-right: 20px;
  width: 100%;
}
.posts_item_list .posts_item:nth-of-type(3n) {
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  .posts_item_list .posts_item {
    margin-bottom: 40px;
  }
}
.posts_item_list .posts_item a {
  display: block;
}
.posts_item {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e2e2e2;
}
.posts_item:last-of-type {
  margin-bottom: 0;
}
.posts_tmb {
  margin: 0;
}
.posts_tmb img {
  box-shadow: 1px 1px 3px #bfbfbf;
  border-radius: 0.25rem;
  height: 180px;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  width: 320px;
}
@media screen and (max-width: 767px) {
  .posts_tmb img {
    /* height: calc(((100vw - 40px) / 160) * 90); */
    width: 100%;
    border-radius: 10px;
  }
}
.posts_date {
  color: #070EF3;
  display: inline-block;
  margin-bottom: 5px;
  font-size: 13px;
}
.posts_title {
  font-size: 16px;
  line-height: 2;
  margin: 0 0 10px;
}
.posts_cat span {
  border: 1px solid;
  color: #070EF3;
  font-size: 12px;
  margin-right: 5px;
  padding: 2px 5px;
}

.no_post {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 767px) {
  .no_post {
    padding: 50px 20px 20px;
  }
  .home .no_post {
    margin-top: 20px;
    padding: 0 0 30px;
  }
}
.no_post h1 {
  font-size: 30px;
  margin: 0 0 50px;
  color: #070EF3;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .no_post h1 {
    font-size: 24px;
    margin-bottom: 30px;
  }
}

.posts_content {
  margin-top: 30px;
}
.posts_content iframe {
  width: 100%;
  margin-bottom: 30px;
}

.pg-blog .blog_tmb {
  margin: 0 0 15px 0;
}

.pg_single_title {
  color: #1e1e1e;
  margin-bottom: 10px;
  text-align: left;
}
.pg_single_contents {
  padding-bottom: 0;
  padding-top: 0;
  max-width: 820px;
}
.pg_single_contents > .blog_tmb {
  margin-bottom: 30px;
}
.pg_single_contents > .blog_tmb img {
  height: 56.25%;
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .pg_single_contents {
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .pg_single_contents {
    padding-bottom: 0;
    padding-top: 0;
  }
}
.pg_single_contents .breadcrumb_block {
  margin-bottom: 50px;
}

.pg-news .posts_item {
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.pg-news .posts_title {
  margin: 0;
}

.wp-block-image {
  margin: 0 0 2em 0;
}
.wp-block-image img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.wp-block-buttons {
  justify-content: center;
}

.wp-block-button__link {
  display: block;
  width: 300px;
  font-size: 14px !important;
  padding: 10px 0;
  border-radius: 50px;
  text-align: center;
  margin: 20px auto;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .wp-block-button__link {
    width: 250px;
    font-size: 16px !important;
  }
}