// --colors--
$white: #fff;
$text-color: #1e1e1e;
$main-color: #070EF3;
$blue: #1068FF;
$blue-light:rgb(212, 228, 255);
$gradation-deep:#0308C1;
$gradation-light:#65ACFE;
$gray: rgba(0, 0, 0, 0.125);
$gray-lighter: #f7f7f7;
$gray-deep:#818181;

$black: #1e1e1e;

$black-light: #3f3f3f;
$gray-light: #e2e2e2;
$orange: #c87523;
$cyan: #3faaae;
$green: #5d9944;
$green-light: #45AF7E;
$lime-green: #82a54e;
$purple: #733fae;
$purple-light: #B470B7;
$brass: #998b44;
$brass-light: #8B8D5E;
$red: #E63A05;
$wine-red: #8a0e3c;
$red-light: #e75d32;
$pink: #d45171;
$magenta: #ef75c4;
$yellow: #f3cb2b;
$yellow-dark: #8E943A;

// --breakpoints--
$breakpoints: ('sm': 'screen and (max-width: 767px)',
	'md': 'screen and (min-width: 768px) and (max-width: 1000px)',
	'md2':'screen and (min-width: 768px) and (max-width: 1400px)',
	'lg': 'screen and (min-width: 1401px)',
	'xl': 'screen and (min-width: 1200px)',
	'xl2': 'screen and (min-width: 1001px)',
	'ie': 'all and (-ms-high-contrast: none)',
) !default;

@mixin mq($breakpoint: md) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}